import { db } from './firebase';
import { doc, getDoc, setDoc, collection, updateDoc, getDocs, query, where } from "firebase/firestore";
import uuid from "uuid-random";

class AppStateManager {
  constructor(userId, onUpdate) {
    this.userId = userId;
    this.userDocRef = doc(db, "users", this.userId);
    this.onUpdate = onUpdate;
    this.state = {
      groups: [],
      cards: [],
      selectGroup: {},
      sessions: [],
      notification: false,
      teams: [],
      teamsAvailable: false, // default to false, indicating no Teams subscription
      selectTeam: {},
      upgradeRequested: false,
    };
    this.initState();
  }

  async initState() {
    if (this.initialized) return;
    this.initialized = true;  

    try {
      const teamsRef = collection(db, "teams");
      const docSnap = await getDoc(this.userDocRef);
      if (docSnap.exists()) {
          const userData = docSnap.data();
          this.state = { ...this.state, ...userData };
  
          // Query for 'admin' teams
          const allPrivateTeamsQuery = query(teamsRef, where("members", "array-contains", { userId: this.userId, role: 'admin' }));
          const privateTeamsSnapshot = await getDocs(allPrivateTeamsQuery);
  
          // Query for 'member' teams
          const allTeamsQuery = query(teamsRef, where("members", "array-contains", { userId: this.userId, role: 'member' }));
          const memberTeamsSnapshot = await getDocs(allTeamsQuery);

          // Query for 'editor' teams
          const allTeamsEditorQuery = query(teamsRef, where("members", "array-contains", { userId: this.userId, role: 'editor' }));
          const editorTeamsSnapshot = await getDocs(allTeamsEditorQuery);
  
          // Combine both snapshots into one set of team data to avoid duplicates
          const combinedTeamsData = new Set(); // Use Set to handle uniqueness
          privateTeamsSnapshot.docs.forEach(doc => combinedTeamsData.add({ id: doc.id, ...doc.data() }));
          memberTeamsSnapshot.docs.forEach(doc => combinedTeamsData.add({ id: doc.id, ...doc.data() }));
          editorTeamsSnapshot.docs.forEach(doc => combinedTeamsData.add({ id: doc.id, ...doc.data() }));
  
          const teamsData = Array.from(combinedTeamsData); // Convert Set to Array
  
          // Update the state with the fetched teams
          this.state.teams = teamsData;

            // Ensure the 'Private Workspace' exists
            let privateTeam = teamsData.find(t => t.name === 'Private Workspace' && t.owner === this.userId);
            if (!privateTeam) {
              const teamId = uuid();
              const teamRef = doc(teamsRef, teamId); // Create a reference to a new document with the generated ID
              await setDoc(teamRef, {
                  name: 'Private Workspace',
                  members: [{ userId: this.userId, role: 'admin' }],
                  owner: this.userId,
                  groups: [],
                  id: teamId // Also use the UUID as the 'id' field in the document
              });
                privateTeam = { id: teamId, name: 'Private Workspace', members: [{userId: this.userId, role: 'admin'}], groups: [] };
                teamsData.push(privateTeam);
                this.state.teams = teamsData;
                this.state.selectTeam = privateTeam;
                this.state.groups = privateTeam.groups;
                // Ensure that privateTeam.groups exists and has at least one element, otherwise set to {}
                this.state.selectGroup = (privateTeam.groups && privateTeam.groups.length > 0) ? privateTeam.groups[0] : [];
                
                console.log("Adding 'Private' team for user:", this.userId);
            } else {
                const updatedSelectTeam = teamsData.find(team => team.id === this.state.selectTeam.id);
                if (updatedSelectTeam) {
                    this.state.selectTeam = updatedSelectTeam;
                    this.state.groups = updatedSelectTeam.groups;
                    this.state.selectGroup = (updatedSelectTeam.groups && updatedSelectTeam.groups.length > 0) ? updatedSelectTeam.groups[0] : {};
                } else {
                    this.state.selectTeam = teamsData[0];
                    this.state.groups = teamsData[0].groups;
                    this.state.selectGroup = (teamsData[0].groups && teamsData[0].groups.length > 0) ? teamsData[0].groups[0] : {};
                }
            }

            await this.persistState();
        } else {
            console.log("No user document found in Firestore, initializing new user and state.");
            const teamId = uuid();
            const teamRef = doc(teamsRef, teamId);

            await setDoc(teamRef, {
                name: 'Private Workspace',
                members: [{ userId: this.userId, role: 'admin' }],
                owner: this.userId,
                groups: [],
                id: teamId
            });
            const privateTeam = { id: teamId, name: 'Private Workspace', members: [{userId: this.userId, role: 'admin'}], groups: [] };
            this.state.teams = [privateTeam];
            this.state.selectTeam = privateTeam;
            this.state.selectGroup = (privateTeam.groups && privateTeam.groups.length > 0) ? privateTeam.groups[0] : [];
            this.state.groups = privateTeam.groups;

            await this.persistState();
        }
    } catch (error) {
        console.error("Error initializing state:", error);
    }
  }

  async persistState() {
    const maxRetries = 5;
    let attempts = 0;

    while (attempts < maxRetries) {
      try {
        // Attempt to clean and persist state
        const cleanState = JSON.parse(JSON.stringify(this.state));
        await setDoc(this.userDocRef, cleanState, { merge: true });

        // Success, so call onUpdate if it's a function
        if (typeof this.onUpdate === 'function') {
          localStorage.setItem('state', JSON.stringify(cleanState));
          this.onUpdate(cleanState);
        }
        return;  // Exit successfully after updating
      } catch (error) {
        attempts++;  // Increment the number of attempts
        if (attempts >= maxRetries) {
          // Log the caller function by examining the stack trace
          const stack = new Error().stack;
          console.log("Persist state called from:", stack);
          console.error("Error persisting state after " + maxRetries + " attempts:", error);
          return;  // Exit function after max attempts
        }
      }
    }
  }

  setGroups(updatedGroups) {
    this.state.groups = updatedGroups;
    this.persistState();  
  }

  // Team methods

  // In your state manager
  setTeams(updatedTeams) {
    this.state.teams = updatedTeams;
    this.persistState();  
  }

  async createTeam(teamId, teamData) {
    const teamRef = doc(db, "teams", teamId);  // Create a reference with the specified ID
    try {
        const teamSnap = await getDoc(teamRef);
        if (teamSnap.exists()) {
            console.log("Team already exists.");
        } else {
            // Prepare the team data with owner and empty groups array
            const completeTeamData = {
                ...teamData,
            };

            // Create the team in Firestore with the complete data
            await setDoc(teamRef, completeTeamData);

            // Update local state to include the new team
            this.state.teams.push({ id: teamRef.id, ...completeTeamData });
            this.state.groups = this.state.teams.groups;
            this.persistState();  // Persist state updates
            console.log("New team created successfully.");
        }
    } catch (error) {
        console.error("Error creating new team:", error);
    }
  }

  async addMemberToTeam(teamId, userId, role = 'member') {
    const teamRef = doc(db, "teams", teamId);
    try {
        const teamSnap = await getDoc(teamRef);
        if (teamSnap.exists()) {
            const teamData = teamSnap.data();
            const existingMemberIndex = teamData.members.findIndex(member => member.userId === userId);

            if (existingMemberIndex === -1) {
                // Member does not exist, add them
                const newMember = { userId: userId, role: role };
                const updatedMembers = [...teamData.members, newMember];
                await updateDoc(teamRef, { members: updatedMembers });

                // Update local state to include the new member
                const localTeam = this.state.teams.find(team => team.id === teamId);
                if (localTeam) {
                    localTeam.members = updatedMembers;
                    console.log("Member added successfully to the team.");
                }
            } else {
                // Member exists, update their role or other details
                teamData.members[existingMemberIndex].role = role;
                await updateDoc(teamRef, { members: teamData.members });

                // Update local state to reflect the role change
                const localTeam = this.state.teams.find(team => team.id === teamId);
                if (localTeam) {
                    localTeam.members = teamData.members;
                    console.log("Member details updated successfully.");
                }
            }

            // Persist updated state
            this.persistState();

        } else {
            console.log("Team not found.");
        }
    } catch (error) {
        console.error("Error updating team members:", error);
    }
  }

  async removeMemberFromTeam(teamId, userId) {
    const teamRef = doc(db, "teams", teamId);
    try {
        const teamSnap = await getDoc(teamRef);
        if (teamSnap.exists()) {
            const teamData = teamSnap.data();
            const memberIndex = teamData.members.findIndex(member => member.userId === userId);

            if (memberIndex !== -1) {
                // Remove the member from Firestore data
                const updatedMembers = teamData.members.filter(member => member.userId !== userId);
                await updateDoc(teamRef, { members: updatedMembers });

                // Update local state
                const localTeam = this.state.teams.find(team => team.id === teamId);
                if (localTeam) {
                    localTeam.members = updatedMembers;
                    console.log("Member removed successfully from the team.");
                }
                
                // Persist updated state
                this.persistState();
            } else {
                console.log("Member not found in the team.");
            }
        } else {
            console.log("Team not found.");
        }
    } catch (error) {
        console.error("Error removing team member:", error);
    }
  }

  async updateMemberRole(teamId, userId, newRole) {
    const teamRef = doc(db, "teams", teamId);

    try {
        const teamDoc = await getDoc(teamRef);

        if (teamDoc.exists()) {
            // Update the role within the members array
            const members = teamDoc.data().members.map(member =>
                member.userId === userId ? { ...member, role: newRole } : member
            );

            // Update Firestore document
            await updateDoc(teamRef, { members });

            // Update local state
            const localTeam = this.state.teams.find(team => team.id === teamId);
            if (localTeam) {
                localTeam.members = members;
                console.log(`Member role updated to ${newRole}.`);
            }

            // Persist the updated state
            this.persistState();
        } else {
            console.log("Team not found.");
        }
    } catch (error) {
        console.error("Error updating member role:", error);
    }
  }

  // Dashboard analytics functions
  getUserSessionsLastWeek(userId) {
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    return this.state.sessions.filter(session => 
      session.userId === userId && new Date(session.dateTime) >= oneWeekAgo
    ).length;
  }

  getTeamSessionsLastWeek(teamId) {
    const team = this.state.teams.find(team => team.id === teamId);
    if (!team) return [];

    const sessionsLastWeek = team.members.map(memberId => ({
      memberId,
      sessions: this.getUserSessionsLastWeek(memberId)
    }));

    return sessionsLastWeek;
  }

  async fetchTeamMembers(team) {
    try {
        const teamRef = doc(db, "teams", team.id);
        const teamSnap = await getDoc(teamRef);

        if (teamSnap.exists()) {
            const teamData = teamSnap.data();

            // Fetch detailed information for each member object
            const memberDetailsPromises = teamData.members.map(async (member) => {
                const userRef = doc(db, "users", member.userId);
                const userSnap = await getDoc(userRef);
                if (userSnap.exists()) {
                    // Constructing the full member object
                    return {
                        id: member.userId, // Include the user's UUID
                        name: userSnap.data().name || "Unknown", // Fallback for any missing data
                        email: userSnap.data().email || "No email provided",
                        role: member.role, // Include the role from the team data
                        ...userSnap.data() // Spread operator to include all user data fields
                    };
                } else {
                    return {
                        id: member.userId,
                        name: "Unknown Member",
                        email: "No email provided",
                        role: member.role || "No role provided" // Handle missing role if any
                    };
                }
            });

            const memberDetails = await Promise.all(memberDetailsPromises);
            return memberDetails; // Return an array of member objects
        } else {
            console.log("No such team found!");
            return [];
        }
    } catch (error) {
        console.error("Error fetching team members:", error);
        return [];
    }
  } 

  updateSubscriptionStatus(isTeamsAvailable) {
    this.state.teamsAvailable = isTeamsAvailable;
    this.persistState();
  }

  setSelectGroup(groupData) {
    this.state.selectGroup = groupData;
    this.persistState();
  }

  setSelectTeam(teamData) {
    this.state.selectTeam = teamData;
    this.persistState();
  }

  addNewGroup(teamId, groupData) {
    return new Promise(async (resolve, reject) => {
        const teamRef = doc(db, "teams", teamId);  // Reference to the team document
        try {
            const teamSnap = await getDoc(teamRef);
            if (teamSnap.exists()) {
                const teamData = teamSnap.data();
                const groups = teamData.groups || [];
                if (!groupData.id) {
                    groupData.id = doc(collection(db, "dummy")).id;  // Firestore does not auto-generate IDs for array elements
                }
                const existingGroup = groups.find(group => group.id === groupData.id);
                console.log(groups.length);
                if (!existingGroup) {
                    groups.push(groupData);  // Add new group to the array
                    await updateDoc(teamRef, { groups: groups });

                    const updatedTeams = this.state.teams.map(team => {
                        if (team.id === teamId) {
                            return {...team, groups: groups};  // Update the specific team
                        }
                        return team;
                    });

                    this.state.teams = updatedTeams;
                    const updatedSelectTeam = updatedTeams.find(team => team.id === this.state.selectTeam.id);
                    if (updatedSelectTeam) {
                        this.selectTeam = updatedSelectTeam;
                        this.selectGroup = groupData; // Assuming you want to select the newly added group
                        this.persistState();  // Save state changes
                        resolve();  // Resolve the promise successfully
                    } else {
                        console.log("Updated select team not found in teams.");
                        reject("Updated select team not found in teams.");  // Reject the promise if no updated team is found
                    }
                } else {
                    console.log("Group already exists.");
                    alert("Scenario exists or max scenarios created");
                    resolve();  // Resolve the promise as there's nothing to update
                }
            } else {
                console.log("Team not found.");
                reject("Team not found.");  // Reject the promise if team is not found
            }
        } catch (error) {
            console.error("Error adding new group:", error);
            reject(error);  // Reject the promise with the error
        }
    });
  }

  async addNewCard(groupId, cardData) {
    // Find the team that contains the group with the given groupId
    let updated = false;
    for (let team of this.state.teams) {
        const group = team.groups.find(group => group.id === groupId);
        if (group) {
            // Add card to the group's card array, ensuring cards array exists
            if (!group.cards) {
                group.cards = [];
            }
            group.cards.push(cardData);

            // Update the group in Firestore
            const teamRef = doc(db, "teams", team.id);
            try {
                await updateDoc(teamRef, { 
                    groups: team.groups  // Update the entire groups array for consistency
                });
                updated = true;
            } catch (error) {
                console.error("Error adding new card:", error);
                return;
            }
            
            // Update the local state only if Firestore update was successful
            if (updated) {
                this.state.teams = this.state.teams.map(t => 
                    t.id === team.id ? {...t, groups: team.groups} : t
                );
                this.persistState();
            }
            break;
        } else if (group?.cards?.length < 20) {
            console.log(group?.cards?.length);
            alert("Maximum number of cards created!");   
        }
    }

    if (!updated) {
        console.log("Group not found for the card.");
    }
  }

  async addNewSession(teamId, sessionData) {
    let updated = false;
    const team = this.state.teams.find(t => t.id === teamId);
    if (team) {
        // Ensure there's a sessions array in the team
        if (!team.sessions) {
            team.sessions = [];
        }
        team.sessions.push(sessionData);

        // Update the team in Firestore
        const teamRef = doc(db, "teams", teamId);
        try {
            await updateDoc(teamRef, { 
                sessions: team.sessions  // Update the sessions array in Firestore
            });
            updated = true;
        } catch (error) {
            console.error("Error adding new session:", error);
            return;
        }

        // Update the local state only if Firestore update was successful
        if (updated) {
            this.state.teams = this.state.teams.map(t => 
                t.id === teamId ? {...t, sessions: team.sessions} : t
            );
            this.persistState();
        }
    } else {
        console.log("Team not found for the session.");
    }
  }

  removeNotification() {
    this.state.notification = false;
    this.persistState();
  }

  listSessionsByDate() {
    return this.state.sessions.sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime));
  }

  async listCardsByGroup(groupId, selectTeamId) {
    try {
        // Reference the specific team document using the selected team ID
        const teamRef = doc(db, "teams", selectTeamId);
        const teamSnap = await getDoc(teamRef);

        // Check if the team document exists
        if (!teamSnap.exists()) {
            throw new Error("Selected team not found.");
        }

        // Retrieve the team's data
        const teamData = teamSnap.data();

        // Ensure that the team has a 'groups' field and is an array
        if (teamData.groups && Array.isArray(teamData.groups)) {
            // Find the group with the specified groupId
            const group = teamData.groups.find(g => g.id === groupId);

            if (group) {
                // If found, return the group's cards or an empty array if none are present
                return group.cards || [];
            }
        }

        // If no group was found or it lacks cards, throw an error
        throw new Error("No group found with the specified ID or no cards present.");

    } catch (error) {
        console.error("Error fetching group cards:", error);
        throw error; // Re-throw error to ensure calling function can handle it
    }
  }

  listGroupsByTeam(teamId) {
    const team = this.state.teams.find(team => team.id === teamId);
    return team ? team.groups : [];
  }

  listGroups() {
    return this.state.teams.groups;
  }

  async deleteCardById(cardId) {
    try {
        let updated = false;  // Flag to check if update is necessary

        // Iterate over each team and each group to find the card to delete
        for (let team of this.state.teams) {
            for (let group of team.groups) {
                if (group.cards && group.cards.some(card => card.id === cardId)) {
                    // Filter out the card to delete
                    const updatedCards = group.cards.filter(card => card.id !== cardId);
                    group.cards = updatedCards;  // Update the group's cards array

                    // Update Firestore
                    const teamRef = doc(db, "teams", team.id);
                    await updateDoc(teamRef, {
                        groups: team.groups  // Update the entire groups array for consistency
                    });

                    updated = true;
                    break;  // Exit loop once the card is found and handled
                }
            }
            if (updated) break;  // If updated, no need to check further teams
        }

        if (!updated) {
            console.log("Card not found.");
            return;
        }

        // Persist the updated state if changes have been made
        this.persistState();
    } catch (error) {
        console.error("Error deleting card:", error);
    }
  }

  getCardById(cardId) {
    // Iterate through each team
    for (const team of this.state.teams) {
        // Iterate through each group within the team
        for (const group of team.groups) {
            // Check if the group has cards and find the card by its ID
            if (group.cards) {
                const card = group.cards.find(card => card.id === cardId);
                if (card) {
                    return card;  // Return the found card
                }
            }
        }
    }
    return null;  // Return null if no card is found
  }

  async updateCardById(cardId, cardData) {
    try {
        let updated = false;  // Flag to check if update is necessary

        // Iterate over each team and each group to find the card to update
        for (let team of this.state.teams) {
            for (let group of team.groups) {
                if (group.cards) {
                    const updatedCards = group.cards.map(card =>
                        card.id === cardId ? { ...card, ...cardData } : card
                    );

                    // Check if any card was updated
                    if (!updated && JSON.stringify(group.cards) !== JSON.stringify(updatedCards)) {
                        group.cards = updatedCards;  // Update the group's cards array

                        // Update Firestore
                        const teamRef = doc(db, "teams", team.id);
                        await updateDoc(teamRef, {
                            groups: team.groups  // Update the entire groups array for consistency
                        });

                        updated = true;
                    }
                }
            }
            if (updated) break;  // If updated, no need to check further teams
        }

        if (!updated) {
            console.log("Card not found or no update needed.");
            return;
        }

        // Persist the updated state if changes have been made
        this.persistState();
    } catch (error) {
        console.error("Error updating card:", error);
    }
  }

  async updateGroupById(teamId, groupId, newGroupData) {
    return new Promise(async (resolve, reject) => {
        const teamRef = doc(db, "teams", teamId);  // Reference to the team document
        try {
            const teamSnap = await getDoc(teamRef);
            if (teamSnap.exists()) {
                const teamData = teamSnap.data();
                const groups = teamData.groups || [];
                const groupIndex = groups.findIndex(group => group.id === groupId);
                if (groupIndex !== -1) {
                    // Update the group data
                    groups[groupIndex] = {...groups[groupIndex], ...newGroupData};
                    await updateDoc(teamRef, { groups: groups });

                    // Update local state
                    const updatedTeams = this.state.teams.map(team => {
                        if (team.id === teamId) {
                            return {...team, groups: groups};  // Update the specific team
                        }
                        return team;
                    });

                    this.state.teams = updatedTeams;
                    const updatedSelectTeam = updatedTeams.find(team => team.id === this.state.selectTeam.id);
                    if (updatedSelectTeam) {
                        this.selectTeam = updatedSelectTeam;

                        // Update selectGroup if it's the group being updated
                        if (this.selectGroup && this.selectGroup.id === groupId) {
                            this.selectGroup = {...this.selectGroup, ...newGroupData};
                        }

                        this.persistState();  // Save state changes
                        resolve("Group updated successfully");  // Resolve the promise successfully
                    } else {
                        reject("Updated select team not found in teams.");  // Reject the promise if no updated team is found
                    }
                } else {
                    reject("Group not found.");  // Reject the promise if group is not found
                }
            } else {
                reject("Team not found.");  // Reject the promise if team is not found
            }
        } catch (error) {
            console.error("Error updating group:", error);
            reject(error);  // Reject the promise with the error
        }
    });
  }

  addMic(mic) {
    this.state.mic = mic;
    this.persistState();
  }

  upgradeRequest() {
    this.state.upgradeRequested = true;
    this.persistState();
  }

  async deleteGroupById(groupId) {
    return new Promise(async (resolve, reject) => {
        try {
            let isGroupDeleted = false; // Flag to track if the group was deleted

            // Update the teams array by removing the group from the relevant team
            const updatedTeams = this.state.teams.map(team => {
                const groups = team.groups || []; // Ensure team.groups is treated as an array
                const updatedGroups = groups.filter(group => group.id !== groupId);
                if (groups.length !== updatedGroups.length) {
                    isGroupDeleted = true; // Set flag if any group is removed
                    return { ...team, groups: updatedGroups }; // Return updated team with modified groups
                }
                return team; // Return team unchanged if no group was removed
            });

            if (isGroupDeleted) {
                // Persist changes to Firestore for each team that had its groups updated
                await Promise.all(updatedTeams.map(async team => {
                    const teamRef = doc(db, "teams", team.id);
                    // Update Firestore with the groups array, even if it is empty
                    await updateDoc(teamRef, { groups: team.groups || [] });
                }));

                // Update state manager's team and group data
                this.state.teams = updatedTeams;
                if (this.state.selectGroup && this.state.selectGroup.id === groupId) {
                    this.selectGroup = {}; // Reset selected group if it was deleted
                }

                // Update manager's selected team if necessary
                const updatedSelectTeam = updatedTeams.find(team => team.id === this.state.selectTeam.id);
                if (updatedSelectTeam) {
                    this.selectTeam = updatedSelectTeam;
                }

                this.persistState(); // Save state changes
                resolve("Group deleted successfully"); // Resolve the promise with success message
            } else {
                reject("No group found to delete"); // Reject the promise if no group was deleted
            }
        } catch (error) {
            console.error("Error deleting group and associated cards:", error);
            reject(error); // Reject the promise with the error
        }
    });
  }
}

export default AppStateManager;
