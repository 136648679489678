import { useContext, createContext, useEffect, useState } from "react";
import {
    GoogleAuthProvider,
    signInWithPopup,
    signOut,
    onAuthStateChanged,
} from "firebase/auth";
import { auth } from "../firebase";
import { doc, setDoc, getFirestore } from "firebase/firestore";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')) || null);
    const [userId, setUserId] = useState(localStorage.getItem('userId') || null);
    const [userDetails, setUserDetails] = useState(JSON.parse(localStorage.getItem('userDetails')) || {}); 

    // Helper function to save user details to Firestore
    const saveUserDetailsToFirestore = async (userDetails) => {
        const db = getFirestore();
        const userRef = doc(db, "users", userDetails.uid); 
        try {
            await setDoc(userRef, userDetails, { merge: true });
            console.log("User details saved to Firestore.");
        } catch (error) {
            console.error("Error saving user details to Firestore:", error);
        }
    };

    const googleSignin = async () => {
        try {
            const provider = new GoogleAuthProvider();
            provider.setCustomParameters({ prompt: 'select_account' });
            const result = await signInWithPopup(auth, provider);
            if (result.user) {
                const { displayName, email, uid } = result.user;
                const userDetails = { name: displayName, email: email, uid: uid };
                setUserDetails(userDetails); 
                setUser(result.user);
                setUserId(uid); 
                localStorage.setItem('user', JSON.stringify(result.user));
                localStorage.setItem('userId', uid);
                localStorage.setItem('userDetails', JSON.stringify(userDetails));
                await saveUserDetailsToFirestore(userDetails);
            }
        } catch (error) {
            console.error("Google Signin Error:", error);
        }
    };

    const logOut = async () => {
        try {
            await signOut(auth);
            setUser(null);
            setUserId(null);
            setUserDetails({});
            localStorage.removeItem('user');
            localStorage.removeItem('userId');
            localStorage.removeItem('userDetails');
            localStorage.clear(); // Clears everything in local storage
        } catch (error) {
            console.error("Logout Error:", error);
        }
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
                setUserId(currentUser.uid);
                setUserDetails({
                    name: currentUser.displayName,
                    email: currentUser.email,
                    uid: currentUser.uid
                });
                localStorage.setItem('user', JSON.stringify(currentUser));
                localStorage.setItem('userId', currentUser.uid);
                localStorage.setItem('userDetails', JSON.stringify({
                    name: currentUser.displayName,
                    email: currentUser.email,
                    uid: currentUser.uid
                }));
            } else {
                setUser(null);
                setUserId(null);
                setUserDetails({});
                localStorage.removeItem('user');
                localStorage.removeItem('userId');
                localStorage.removeItem('userDetails');
            }
        });

        return () => unsubscribe();
    }, []);

    return (
        <AuthContext.Provider value={{ user, userId, userDetails, googleSignin, logOut }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
