import React, { useCallback, useEffect, useState } from "react";
import { getFirestore, collection, query, where, getDocs, getDoc, doc, updateDoc, arrayRemove } from "firebase/firestore";
import { BsXLg } from "react-icons/bs";
import { useAuth } from "../../context/auth";
import uuid from "uuid-random";
import AddMember from "./add-member";
import CreateTeamModal from "../../components/create-team-modal/create-team-modal";
import { useAppState } from "../../context/appstate"; 
// import { Tooltip } from 'react-tooltip';

import { RiTeamFill } from "react-icons/ri";
import { FaTrashAlt } from "react-icons/fa";

import AppStateManager from "../../state-manager";

import "./styles.css";

function MembersPage() {
    const { user, logOut } = useAuth();
    const [sessions, setSessions] = useState([]);
    const [teamMembers, setTeamMembers] = useState([]);
    const [selectTeam, setSelectTeam] = useState(null); 
    const [showModal, setShowModal] = useState(false);
    const [selectedSession, setSelectedSession] = useState(null);
    const [chartData, setChartData] = useState([]);
    const [appStateManager, setAppStateManager] = useState(null);
    const [teams, setTeams] = useState([]);
    const [teamsAvailable, setTeamsAvailable] = useState(false);
    const [showAddMemberModal, setShowAddMemberModal] = useState(false);
    const [memberEmail, setMemberEmail] = useState("");

    const { state, manager } = useAppState();

    useEffect(() => {
        if (state && manager) {
            setTeams(state.teams || []);
            setSelectTeam(state.selectTeam || {});
            setTeamsAvailable(state.teamsAvailable || false);
        }
    }, [state, manager]);

    useEffect(() => {
        if (selectTeam) {
            manager.fetchTeamMembers(selectTeam).then(members => {
                setTeamMembers(members);
            });
        }
    }, [selectTeam, manager]);
   

    const processDataForChart = (sessions) => {
      const countsByDate = {};
    
      sessions?.forEach(session => {
        const date = new Date(session.dateTime).toLocaleDateString();
        if (!countsByDate[date]) {
          countsByDate[date] = { date, conversations: 0 };
        }
        countsByDate[date].conversations += 1; // Or any other metric you want to count
      });
    
      return Object.values(countsByDate);
    };
      

    useEffect(() => {
      const updateSessions = () => {
        if (appStateManager) {
          const loadedSessions = appStateManager.listSessionsByDate();
          setSessions(loadedSessions);
          setChartData(processDataForChart(loadedSessions));
        }
      };
    
      updateSessions();
    }, [appStateManager]);      

    const viewSessionDetails = (sessionId) => {
        const session = sessions.find(session => session.id === sessionId);
        setSelectedSession(session);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedSession(null);
    };

    const openAddMemberModal = () => {
        setShowAddMemberModal(true);
      };

    const closeAddMemberModal = () => {
      setShowAddMemberModal(false);
    };

    const handleAddMember = async () => {
        if (memberEmail.trim().length > 0 && selectTeam) {
            const db = getFirestore();
            const usersRef = collection(db, "users");
            const q = query(usersRef, where("email", "==", memberEmail.trim()));
    
            try {
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    const userDoc = querySnapshot.docs[0];
                    const userId = userDoc.id;  // Fetching the UUID
    
                    const newMember = {
                        id: userId,
                        name: userDoc.data().name || "Unknown",  // Handle potential missing data
                        email: userDoc.data().email || "No email provided",  // Handle potential missing data
                        ...userDoc.data()  // Include all other user data fields
                    };
    
                    if (manager) {
                        // Add new member to team document
                        manager.addMemberToTeam(selectTeam.id, userId).then(() => {
                            // Update UI state to include new member
                            setTeamMembers(prevMembers => {
                                const isMemberAlreadyAdded = prevMembers.some(member => member.id === newMember.id);
                                return isMemberAlreadyAdded ? prevMembers : [...prevMembers, newMember];
                            });
                            console.log("Member added successfully to the team.");
                        }).catch(error => {
                            console.error("Failed to add member to the team:", error);
                        });
                    }
                } else {
                    console.log("No user found with that email");
                    alert("No user found with that email");
                }
            } catch (error) {
                console.error("Error adding member:", error);
            }
            setMemberEmail("");  // Reset input
            setShowAddMemberModal(false);  // Close modal
        }
    };    

    const handleRemoveMember = async (memberId) => {
        if (selectTeam && memberId) {
            const db = getFirestore();
            try {
                await manager.removeMemberFromTeam(selectTeam.id, memberId);
                const userRef = doc(db, "users", memberId);
    
                // Define the team entry to be removed
                const teamEntryToRemove = {
                    id: selectTeam.id,
                    name: selectTeam.name,
                    members: [memberId]  // Adjust as necessary based on your structure
                };
    
                // Remove the team entry from the user's document
                await updateDoc(userRef, {
                    teams: arrayRemove(teamEntryToRemove)
                });
                
                // Update UI state to remove the member
                setTeamMembers(prevMembers => prevMembers.filter(member => member.id !== memberId));
            } catch (error) {
                console.error("Error removing member from team or updating user's document:", error);
            }
        }
    }; 

    function handleAddMemberInputChange(e) {
        setMemberEmail(e.target.value);
    }

    const handleRoleChange = async (memberId, newRole) => {
        // Assuming manager can handle role updates
        if (selectTeam && memberId && newRole) {
            try {
                await manager.updateMemberRole(selectTeam.id, memberId, newRole);
                // Update local state to reflect the role change
                setTeamMembers(prevMembers =>
                    prevMembers.map(member =>
                        member.id === memberId ? { ...member, role: newRole } : member
                    )
                );
                console.log("Role updated successfully.");
            } catch (error) {
                console.error("Failed to update role:", error);
            }
        }
    };
    
  return (
    <div className="activity-holder">
        {showAddMemberModal && 
            <AddMember
            closeModal={closeAddMemberModal}
            inviteMember={handleAddMember}
            memberEmail={memberEmail}
            handleAddMemberInputChange={handleAddMemberInputChange} />
        }
        <div className="members-container">
            <div className="component-container">
            <div className="member-title-btn-container">
                <div className="small-title">Team Members</div>
                <div className="secondary-btn" onClick={openAddMemberModal}>+ Add Member</div>
            </div>
            <div className="title-description">List of team members</div>
            <div className="table-holder">
                <table>
                    <thead>
                        <tr>
                            <th>NAME</th>
                            <th>EMAIL</th>
                            <th>ROLE</th>
                            <th>ACTIONS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {teamMembers.map((member) => (
                            <tr key={member.id}>
                                <td>{member.name}</td>
                                <td>{member.email}</td>
                                <td>
                                    <select
                                        value={member.role}
                                        onChange={(e) => handleRoleChange(member.id, e.target.value)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <option value="admin">Admin</option>
                                        <option value="member">Member</option>
                                        <option value="editor">Editor</option>
                                    </select>
                                </td>
                                <td><FaTrashAlt key={member.id} onClick={() => handleRemoveMember(member.id)} /></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {showModal && (
                    <div className="modal">
                        <h3 className="session-modal-title">Session Details</h3>
                        <p className="small-title">Date/Time</p>
                        <div className="title-description">{selectedSession && new Date(selectedSession.dateTime).toLocaleString()}</div>
                        <p className="small-title">Transcript</p>
                        <div className="title-description overflow-auto">{selectedSession && selectedSession.transcript}</div>
                        <button className="close-session-modal-button" onClick={closeModal}><BsXLg /></button>
                    </div>
                )}
                {/* Overlay to cover the rest of the screen when modal is open */}
                {showModal && (
                    <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 999 }} onClick={closeModal}></div>
                )}
            </div>
            </div>
        </div>
    </div>
  );
}

export default MembersPage;
