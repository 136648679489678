import React, { useCallback, useEffect, useState } from "react";
import { IoIosSettings } from "react-icons/io";
import { FaPlay } from "react-icons/fa";
import { MdSpaceDashboard } from "react-icons/md";
import { FaUserCircle } from "react-icons/fa";
import { CiSearch } from "react-icons/ci";
import { Link } from "react-router-dom";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer, Tooltip } from 'recharts';
import { BsXLg } from "react-icons/bs";
import { IoIosArrowDown } from "react-icons/io";
import { useAuth } from "../../context/auth";
import { useAppState } from "../../context/appstate"; 
// import { Tooltip } from 'react-tooltip';

import "./styles.css";

function DashboardPage() {
    const { user } = useAuth();
    const [sessions, setSessions] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedSession, setSelectedSession] = useState(null);
    const [chartData, setChartData] = useState([]);
    const [conversionCount, setConversionCount] = useState(0);
    const [highlightedKeyword, setHighlightedKeyword] = useState("");
    const [teamsAvailable, setTeamsAvailable] = useState(false);
    const [teams, setTeams] = useState([]);
    const [selectTeam, setSelectTeam] = useState({});
    const [selectedSessionCards, setSelectedSessionCards] = useState([]);
    const [recentSessions, setRecentSessions] = useState([]);

    const { state, manager } = useAppState();

     // Define getCardDetails right after state definitions to ensure it is initialized before any useEffect
     const getCardDetails = useCallback(() => {
      if (!selectedSession || !state.selectTeam.groups) return [];
      console.log(state.selectTeam.groups);
      const shownCardDetails = [];
      state.selectTeam.groups.forEach(group => {
          group.cards?.forEach(card => {
              if (selectedSession.cardsShown?.includes(card.id)) {
                  shownCardDetails.push({
                      title: card.title,
                      keywords: card.keywords.join(', '),
                      description: card.description
                  });
              }
          });
      });
      return shownCardDetails;
    }, [selectedSession, state.selectTeam]);

    const processRecentSessions = (sessions) => {
      const sortedSessions = sessions.sort((a, b) => {
        // Assuming dateTime is stored in an ISO format or comparable string that sorts correctly
        return new Date(b.dateTime) - new Date(a.dateTime);
      });
      setRecentSessions(sortedSessions);
    }

    const parseTranscript = useCallback((transcript) => {
      if (!transcript || !selectedSessionCards.length) return [{ text: transcript, isKeyword: false }];
  
      let parsedTranscript = [];
      let lastIndex = 0;
  
      // Flatten and sort keywords by length descending to prioritize longer matches
      const keywords = selectedSessionCards.flatMap(card =>
          card.keywords.split(', ').map(keyword => keyword.trim().toLowerCase())
      ).sort((a, b) => b.length - a.length);
  
      // Create a regex from the keywords, escaping special characters
      const keywordsRegex = new RegExp(keywords.map(kw => kw.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join('|'), 'gi');
  
      // Use the regex to find and mark keywords in the transcript
      transcript.replace(keywordsRegex, (match, offset) => {
          // Push text before the keyword (if any)
          if (offset > lastIndex) {
              parsedTranscript.push({ text: transcript.substring(lastIndex, offset), isKeyword: false });
          }
          // Push the keyword
          parsedTranscript.push({ text: match, isKeyword: true });
          lastIndex = offset + match.length;
      });
  
      // Add remaining text if any
      if (lastIndex < transcript.length) {
          parsedTranscript.push({ text: transcript.substring(lastIndex), isKeyword: false });
      }
  
      return parsedTranscript;
    }, [selectedSessionCards]);

    useEffect(() => {
      
    }, [highlightedKeyword]);

    useEffect(() => {
      if (state && manager && selectTeam && state.teams && selectTeam.id) {
          const team = state.teams.find(t => t.id === selectTeam.id);
  
          if (team && team.sessions) {
              // Filter sessions to include only those that match the user's UID
              const mySessions = team.sessions.filter(session => session.owner === user.uid);
  
              // Then sort these sessions by dateTime
              const sortedSessions = mySessions.sort((a, b) => {
                  return new Date(a.dateTime) - new Date(b.dateTime);
              });
  
              setSessions(sortedSessions);
          } else {
              setSessions([]);
          }
  
          setTeams(state.teams || []);
          setSelectTeam(state.selectTeam || {});
          setTeamsAvailable(state.teamsAvailable || false);
      }
    }, [state, manager, selectTeam, user.uid]); 

    useEffect(() => {
      setSelectTeam(state.selectTeam || {});
    }, [state.selectTeam]); 

    useEffect(() => {
      setChartData(processDataForChart(sessions));
      setRecentSessions(processRecentSessions(sessions));
      
      let conversionCount = 0;
      sessions?.forEach(session => {
          if (session.conversion) {
              conversionCount++;
          }
      });
      setConversionCount(conversionCount);
    }, [sessions]);  

    useEffect(() => {
      if (selectedSession) {
        const cardDetails = getCardDetails();
        setSelectedSessionCards(cardDetails); 
      }
    }, [selectedSession, getCardDetails]);

    const processDataForChart = (sessions) => {
      const countsByDate = {};
  
      sessions?.forEach(session => {
          const date = new Date(session.dateTime).toLocaleDateString();
          if (!countsByDate[date]) {
              countsByDate[date] = { date, conversations: 0, conversions: 0 };
          }
          countsByDate[date].conversations += 1; // Increment total sessions
          if (session.conversion) {
              countsByDate[date].conversions += 1; // Increment conversions if true
          }
      });
  
      return Object.values(countsByDate);
    };
    
    const viewSessionDetails = (sessionId) => {
        const session = sessions.find(session => session.id === sessionId);
        setSelectedSession(session);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedSession(null);
    };

    const handleKeywordHover = useCallback((text) => {
      const keyword = text.toLowerCase().replace(/[.,!?;]+$/, ''); // Strip punctuation for matching
      setHighlightedKeyword(keyword);
    }, []);
  
    const handleKeywordLeave = useCallback(() => {
        setHighlightedKeyword(null);
    }, []);  

    const isCardHighlighted = useCallback((card) => {
      if (!highlightedKeyword) return false;
      return card.keywords.toLowerCase().split(', ').some(kw => {
          // Adding word boundaries to ensure complete keyword matching
          const regex = new RegExp(`\\b${kw}\\b`, 'i'); // Ensures complete word match
          const matchResult = regex.test(highlightedKeyword);
          return matchResult;
      });
    }, [highlightedKeyword]);
    
  return (
    <section className="group-page-section">
      <div className="right-holder">
        <div className="top-bar">
          <div className="search">
            <div className="search-bar">
              <div className="search-icon"><CiSearch /></div>
            </div>
          </div>
        </div>
        <div className="teams-content-holder">
          <div className="teams-modal-container">
            <div className="activity-holder">
                <div className="date-picker-container">
                  <div className="date-picker">Last 30 days<IoIosArrowDown /></div>
                </div>
                <div className="metrics-component-container">
                  <div className="main-metrics-container">
                    <div className="main-metric">
                      <div className="main-metric-title">Total Sessions</div>
                      <div className="main-metric-value">{sessions.length}</div>
                    </div>
                    <div className="main-metric">
                      <div className="main-metric-title">Total Conversions</div>
                      <div className="main-metric-value">{conversionCount}</div>
                    </div>
                    <div className="main-metric">
                      <div className="main-metric-title">Top Mentions</div>
                      <div className="main-metric-value">2020 Jeep Grand Cherokee</div>
                    </div>
                    <div className="main-metric no-margin">
                      <div className="main-metric-title">Top Card Shown</div>
                      <div className="main-metric-value metric-card">Pricing</div>
                    </div>
                  </div>
                </div>
                <div className="small-title">Recent Sessions</div>
                <div className="title-description">List of your recent sessions</div>
                <div className="table-holder">
                <table>
                    <thead>
                        <tr>
                            <th>SESSION</th>
                            <th>DATE/TIME</th>
                            <th># OF CARDS USED</th>
                            <th>ACTIONS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sessions?.map((session, index) => (
                            <tr key={index} onClick={() => viewSessionDetails(session.id)}>
                                <td>{`Session ${index + 1}`}</td>
                                <td>{new Date(session.dateTime).toLocaleString()}</td>
                                {/* Assuming you store card count in the session or calculate it */}
                                <td>{session.cardsShown?.length || '0'}</td>
                                <td>
                                    {/* Actions like View Details, Edit, Delete, etc. */}
                                    View
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {showModal && (
                    <div className="modal">
                        <h3 className="session-modal-title">Session Details</h3>
                        <p className="small-title">Date/Time</p>
                        <div className="title-description">{selectedSession && new Date(selectedSession.dateTime).toLocaleString()}</div>
                        <p className="small-title">Conversion</p>
                        <div className="title-description">{selectedSession.conversion ? "true" : "false"}</div>
                        <p className="small-title">Cards Shown</p>
                        <div className="title-description cards-shown-container">
                            {selectedSessionCards.map((card, index) => (
                                <div key={card.id || index} className={`shown-card ${isCardHighlighted(card) ? 'highlight' : ''}`}>
                                    <p>{card.title}</p>
                                </div>
                            ))}
                        </div>
                        <p className="small-title">AI Feedback</p>
                        <div className="title-description">{selectedSession.feedback || "none"}</div>
                        <p className="small-title">Transcript</p>
                        <div className="title-description overflow-auto">
                            {selectedSession && parseTranscript(selectedSession.transcript).map((segment, index) => (
                                <span key={`${segment.text}-${index}`} className={segment.isKeyword ? "keyword" : ""} onMouseEnter={() => segment.isKeyword && handleKeywordHover(segment.text)} onMouseLeave={handleKeywordLeave}>
                                    {segment.text}
                                </span>
                            ))}
                        </div>
                        <button className="close-session-modal-button" onClick={closeModal}><BsXLg /></button>
                    </div>
                )}
                {/* Overlay to cover the rest of the screen when modal is open */}
                {showModal && (
                    <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 999 }} onClick={closeModal}></div>
                )}
                </div>
                <div className="small-title">Last Week Activity</div>
                <div className="title-description">List of conversations over the last week</div>
                <ResponsiveContainer width="100%" height={300}>
                <LineChart
                    data={chartData}
                    margin={{
                        top: 25,
                        right: 30,
                        bottom: 5,
                        left: 0,
                    }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="conversations" stroke="#8884d8" activeDot={{ r: 8 }} />
                    <Line type="monotone" dataKey="conversions" stroke="#82ca9d" activeDot={{ r: 8 }} />
                </LineChart>
            </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DashboardPage;
