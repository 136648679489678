import React, { useState, useEffect } from "react";
import { BsXLg } from "react-icons/bs";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import "./styles.css";

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

function Card({ cardData, closeModal, deleteCard, onSave }) {
  const [title, setTitle] = useState(cardData?.title || '');
  const [keywords, setKeywords] = useState(cardData?.keywords.join(", ") || '');
  const [description, setDescription] = useState(cardData?.description || '');  

  function handleSaveChanges() {
    const rawCardKeywords = keywords.split(",");
    const cleanedKeywords = rawCardKeywords.map(keyword => keyword.trim()).filter(keyword => keyword !== "");
    onSave({
        title: title.trim(),
        keywords: cleanedKeywords,
        description: description.trim(), // Ensure description is trimmed as well
    });
    closeModal();
  }

  return (
      <div className="create-card-modal">
        <h3 className="modal-title">{cardData.title}</h3>
        <div
          className="close-button-holder"
          onClick={() => {
            closeModal();
          }}
        >
          <BsXLg />
        </div>

        <input
          type="text"
          placeholder="Card Title"
          className="group-name-input"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        />
        <input
          type="text"
          placeholder="Card Keywords"
          className="group-name-input"
          value={keywords}
          onChange={(e) => {
            setKeywords(e.target.value);
          }}
        />
        <ReactQuill
          className="card-description"
          theme="snow"
          modules={modules}
          formats={formats}
          value={description}
          onChange={(data) => {
            setDescription(data);
          }}
        />

        <span
          className="create-group-btn-modal save-btn"
          onClick={handleSaveChanges}
        >
          Save
        </span>
        <span
          className="create-group-btn-modal delete-btn"
          onClick={() => {
            deleteCard(cardData.id);
            closeModal();
          }}
        >
          Delete
        </span>
      </div>
    // </Resizable>
  );
}

export default Card;
