import React from "react";
import { BsXLg } from "react-icons/bs";

import "./styles.css";

function TeamName({
    teamName,
    handleTeamNameInputChange,
    saveTeamName,
    closeModal,
}) {
  return (
    <div>
        <div className="team-modal-wrapper" onClick={closeModal}></div>
        <div className="create-team-modal">
        <h3 className="modal-title">Update Team Name</h3>
        <div className="close-button-holder" onClick={closeModal}>
            <BsXLg />
        </div>
        <input
            type="text"
            placeholder="Team Name"
            className="group-name-input"
            value={teamName}
            onChange={handleTeamNameInputChange}
        />
        <span className="create-group-btn-modal" onClick={saveTeamName}>
            Update
        </span>
        </div>
    </div>
  );
}

export default TeamName;
