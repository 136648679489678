import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IoIosSettings } from "react-icons/io";
import { FaPlay } from "react-icons/fa";
import { MdSpaceDashboard } from "react-icons/md";
import { FaChevronLeft } from "react-icons/fa";
import { RiTeamFill } from "react-icons/ri";
import { BiExpandVertical } from "react-icons/bi";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../context/auth";
import { useAppState } from "../../context/appstate"; 

import "react-tooltip/dist/react-tooltip.css";
import "./styles.css";

function LeftMenu() {
    const { user, logOut } = useAuth();
    const [teams, setTeams] = useState([]);
    const [selectTeam, setSelectTeam] = useState({});
    const [teamsAvailable, setTeamsAvailable] = useState(false);
    const location = useLocation();
    const [menuItems, setMenuItems] = useState([
        { key: 'sessions', icon: <FaPlay />, text: 'Sessions', link: '/create' },
        { key: 'dashboard', icon: <MdSpaceDashboard />, text: 'My Activity', link: '/dashboard' },
        { key: 'settings', icon: <IoIosSettings />, text: 'Settings', link: '/settings' }
    ]);

    const showLeftMenu = location.pathname !== "/";

    const { state, manager } = useAppState();

    useEffect(() => {
        let items = [
            { key: 'sessions', icon: <FaPlay />, text: 'Sessions', link: '/create' },
            { key: 'dashboard', icon: <MdSpaceDashboard />, text: 'My Activity', link: '/dashboard' },
            { key: 'settings', icon: <IoIosSettings />, text: 'Settings', link: '/settings' }
        ];
        if (teamsAvailable) {
            const teamsIndex = items.findIndex(item => item.key === 'sessions') + 1;
            items.splice(teamsIndex, 0, {
                key: 'teams', icon: <RiTeamFill />, text: 'Teams', link: '/teams'
            });
        }
        setMenuItems(items);
    }, [teamsAvailable]);

    useEffect(() => {
        if (!showLeftMenu) {
            const appContainer = document.getElementsByClassName('app-container')[0];
            if (appContainer) {
                appContainer.style.display = "block";
            }
        } else {
            const appContainer = document.getElementsByClassName('app-container')[0];
            if (appContainer) {
                appContainer.style.display = "flex";
            }
        }
    }, [showLeftMenu]);
  
    useEffect(() => {
        if (state && manager) {
            if (state.teams !== teams) {
                setTeams(state.teams || []);
            }
            if (state.selectTeam !== selectTeam) {
                setSelectTeam(state.selectTeam || {});
            }
            if (state.teamsAvailable !== teamsAvailable) {
                setTeamsAvailable(state.teamsAvailable || false);
            }
        }
    }, [state, manager]);    
    
    async function handleLogout() {
        try {
          await logOut();
        } catch (error) {
          console.log(error);
        }
      }

    const handleSelectTeamsChange = useCallback((e) => {
        const teamId = e.target.value;
        const team = state.teams.find(team => team.id === teamId);
        if (team && manager) {
            manager.setSelectTeam(team);
            if (team.groups.length >= 1) {
                manager.setSelectGroup(team.groups[0]);
                manager.setGroups(team.groups);
            } else {
                manager.setSelectGroup({});
                manager.setGroups([]);
            }
            setSelectTeam(team);  // This could be redundant if your UI reacts directly to changes in the AppStateContext
        }
    }, [state.teams, manager]); 
  
    return (
        <React.Fragment>
            {showLeftMenu &&
                <div className="left-menu">
                    <div className="workspace-text">
                        <select
                        key={selectTeam.id || 'default-key'}
                        className="teams-dropdown"
                        title={selectTeam.name}
                        value={selectTeam.id || ''}
                        onChange={handleSelectTeamsChange}
                        >
                            {teams.map((team) => (
                                <option key={team.id} value={team.id}>
                                    {team.name === 'Private Workspace'
                                    ? team.name
                                    : `🏢 ${team.name}`} 
                                </option>                            
                            ))}
                        </select>
                    </div>
                    <div className="user-holder">
                        <div className="user-img">
                            <img src={user?.photoURL} className="google-profile-img" />
                        </div>
                        <div className="user-name-holder">
                            <div className="user-name">{user?.displayName}</div>
                            <div className="user-name-role">Admin</div>
                        </div>
                    </div>
                    <div className="menu-items-holder">
                    {menuItems.map(item => (
                        <Link to={item.link} key={item.key} className={`menu-item ${location.pathname === item.link ? 'active' : ''}`}>
                        {item.icon}
                        <div className="menu-text">{item.text}</div>
                        </Link>
                    ))}
                    </div>
                    <div className="logout-btn" onClick={handleLogout}>Log Out</div>
                </div>
            }
        </React.Fragment>
    );
  }
  
  export default LeftMenu;