import React from "react";
import { BsXLg } from "react-icons/bs";

import "./styles.css";

function AddMember({
    memberEmail,
    handleAddMemberInputChange,
    inviteMember,
    closeModal,
}) {
  return (
    <div>
        <div className="team-modal-wrapper" onClick={closeModal}></div>
        <div className="create-team-modal">
        <h3 className="modal-title">Add Member</h3>
        <div className="close-button-holder" onClick={closeModal}>
            <BsXLg />
        </div>
        <input
            type="text"
            placeholder="Member email"
            className="group-name-input"
            value={memberEmail}
            onChange={handleAddMemberInputChange}
        />
        <span className="create-group-btn-modal" onClick={inviteMember}>
            Invite
        </span>
        </div>
    </div>
  );
}

export default AddMember;
