import React from "react";
import { BsXLg } from "react-icons/bs";

import "./styles.css";

function GroupEditModal({
    scenarioName,
    handleScenarioNameInputChange,
    updateScenarioName,
    closeModal,
}) {
  return (
    <div>
        <div className="scenario-modal-wrapper" onClick={closeModal}></div>
        <div className="create-team-modal">
        <h3 className="modal-title">Update Scenario Name</h3>
        <div className="close-button-holder" onClick={closeModal}>
            <BsXLg />
        </div>
        <input
            type="text"
            placeholder="Scenario Name"
            className="group-name-input"
            value={scenarioName}
            onChange={handleScenarioNameInputChange}
        />
        <span className="create-group-btn-modal" onClick={updateScenarioName}>
            Update
        </span>
        </div>
    </div>
  );
}

export default GroupEditModal;
