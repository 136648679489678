import React from "react";
import { BsXLg } from "react-icons/bs";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import "./styles.css";

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

function CreateModalButton({
  cardTitleText,
  cardKeywords,
  cardDesc,
  setCardTitleText,
  setCardKeywords,
  setCardDesc,
  createCard,
  closeModal,
}) {
  function handleCardTitleChange(e) {
    setCardTitleText(e.target.value);
  }

  function handleCardKeywordsChange(e) {
    setCardKeywords(e.target.value);
  }

  function handleCardDescChange(data) {
    setCardDesc(data);
  }

  return (
    <div className="create-card-modal">
      <h3 className="modal-title">Create Card</h3>
      <div
        className="close-button-holder"
        onClick={() => {
          closeModal();
        }}
      >
        <BsXLg />
      </div>
      <input
        type="text"
        placeholder="Card Title"
        className="group-name-input"
        value={cardTitleText}
        onChange={handleCardTitleChange}
      />
      <input
        type="text"
        placeholder="Keywords/Phrases separated by comma"
        className="group-name-input"
        value={cardKeywords}
        onChange={handleCardKeywordsChange}
      />
      {/* <textarea className="card-description"></textarea> */}
      <ReactQuill
        className="card-description"
        theme="snow"
        modules={modules}
        formats={formats}
        value={cardDesc}
        onChange={handleCardDescChange}
      />
      <span className="create-group-btn-modal" onClick={createCard}>
        Save
      </span>
    </div>
  );
}

export default CreateModalButton;
