import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IoIosSettings } from "react-icons/io";
import { FaPlay } from "react-icons/fa";
import { MdSpaceDashboard } from "react-icons/md";
import { FaUserCircle } from "react-icons/fa";
import { CiSearch } from "react-icons/ci";
import MicSettingsModal from "../../components/mic-settings/mic-settings-modal";
import { Link } from "react-router-dom";
import { RiTeamFill } from "react-icons/ri";
import { Tooltip } from 'react-tooltip';
import { useAuth } from "../../context/auth";
import AppStateManager from "../../state-manager";

import "./styles.css";

function SettingsPage() {
  const { user, logOut } = useAuth();
  const [teamsAvailable, setTeamsAvailable] = useState(false);
  const [appStateManager, setAppStateManager] = useState(null);

  useEffect(() => {
    if (user) {
      const manager = new AppStateManager(user.uid);
      manager.initState().then(() => {
        setAppStateManager(manager);
        // setGroups(manager.state.groups || []);
        // setSelectGroup(manager.state.selectGroup || {});
        // setNotification(manager.state.notification || false);
        // Handle teams data
        setTeamsAvailable(manager.state.teamsAvailable || false);
      });
    }
  }, [user]);

  return (
    <section className="group-page-section">
      <div className="right-holder">
        <div className="top-bar">
          <div className="search">
            <div className="search-bar">
              <div className="search-icon"><CiSearch /></div>
            </div>
          </div>
        </div>
        <div className="content-holder">
          <div className="modal-container">
            <div className="activity-holder">
              <MicSettingsModal />
              <div className="small-title">Team Settings</div>
              <div className="title-description">Coming soon...</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SettingsPage;
