import React from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { AuthContextProvider, useAuth } from './context/auth'; // Make sure to import useAuth
import { AppStateProvider } from './context/appstate';
import LeftMenu from "./components/left-menu/left-menu";
import CreateGroupPage from "./pages/create-group";
import SettingsPage from "./pages/settings";
import HomePage from "./pages/home";
import DashboardPage from "./pages/dashboard/dashboard";
import TeamsPage from "./pages/teams/teams";
import Protected from "./components/protected-router/index";
import "./App.css";

function AppWrapper() {
  return (
    <BrowserRouter>
      <AuthContextProvider>
        <App />
      </AuthContextProvider>
    </BrowserRouter>
  );
}

function App() {
  const { user } = useAuth(); 
  const location = useLocation();
  // Determine if the current path is the homepage
  const isHomePage = location.pathname === '/';

  return (
    <AppStateProvider userId={user?.uid}>
      <div className="app-container">
        <LeftMenu />
        <div className={`main-content ${isHomePage ? 'home-width' : ''}`}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/create" element={<Protected><CreateGroupPage /></Protected>} />
            <Route path="/settings" element={<Protected><SettingsPage /></Protected>} />
            <Route path="/dashboard" element={<Protected><DashboardPage /></Protected>} />
            <Route path="/teams" element={<Protected><TeamsPage /></Protected>} />
          </Routes>
        </div>
      </div>
    </AppStateProvider>
  );
}

export default AppWrapper;
