import React from "react";
import { BsXLg } from "react-icons/bs";

import "./styles.css";

function CreateTeamModal({
    teamInputText,
    handleTeamInputChange,
    createTeam,
    closeModal,
}) {
  return (
    <div>
        <div className="team-modal-wrapper" onClick={closeModal}></div>
        <div className="create-team-modal">
        <h3 className="modal-title">Create Team</h3>
        <div className="close-button-holder" onClick={closeModal}>
            <BsXLg />
        </div>
        <input
            type="text"
            placeholder="Team Name"
            className="group-name-input"
            value={teamInputText}
            onChange={handleTeamInputChange}
        />
        <span className="create-group-btn-modal" onClick={createTeam}>
            Create
        </span>
        </div>
    </div>
  );
}

export default CreateTeamModal;
