import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import axios from 'axios';
import { BsFillCaretRightFill } from "react-icons/bs";
import { BsFileFill } from "react-icons/bs";
import { FaRegSquarePlus } from "react-icons/fa6";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { MdDeleteOutline } from "react-icons/md";
import { CiSearch } from "react-icons/ci";
import { BsXLg } from "react-icons/bs";
import { IoWarningOutline } from "react-icons/io5";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import CreateGroupModal from "../../components/create-group-modal/create-group-modal";
import CreateCardModal from "../../components/create-card-modal/create-card-modal";
import CardModal from "../../components/card-modal/card-modal";
import MicSettingsModal from "../../components/mic-settings/mic-settings-modal";
import OpenCardModal from "../../components/open-card-modal/open-card-modal";
import uuid from "uuid-random";
import { Tooltip } from "react-tooltip";
import { useAuth } from "../../context/auth";
import GroupEditModal from "./edit-scenario";

import AppStateManager from "../../state-manager";
import { useAppState } from '../../context/appstate';

import { getFirestore, onSnapshot, doc } from 'firebase/firestore';

import "react-tooltip/dist/react-tooltip.css";
import "./styles.css";

function CreateGroupPage() {
  const { user, logOut } = useAuth();
  const [appStateManager, setAppStateManager] = useState(null);
  const [groups, setGroups] = useState([]);
  const [cards, setCards] = useState([]);
  const [groupCards, setGroupCards] = useState([]);
  const [selectCard, setSelectCard] = useState({});
  const [selectGroup, setSelectGroup] = useState({});
  const [showNotification, setShowNotification] = useState(false);
  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
  const [showCreateCardModal, setShowCreateCardModal] = useState(false);
  const [showCardModal, setShowCardModal] = useState(false);
  const [showMicSettingsModal, setShowMicSettingsModal] = useState(false);
  const [matchingCards, setMatchingCards] = useState([]);
  const [sessionCards, setSessionCards] = useState([]);
  const [transcript, setTranscript] = useState([]);
  const [interimTranscript, setInterimTranscript] = useState([]);
  const [isListening, setIsListening] = useState(false);
  const [permissionGranted, setPermissionGranted] = useState(false);
  const [cardTitleText, setCardTitleText] = useState("");
  const [cardKeywords, setCardKeywords] = useState("");
  const [scenarioName, setScenarioName] = useState("");
  const [cardDesc, setCardDesc] = useState("");
  const [groupInputText, setGroupInputText] = useState("");
  const [selectTeam, setSelectTeam] = useState(null);
  const [teams, setTeams] = useState([]);
  const [teamsAvailable, setTeamsAvailable] = useState(false);
  const [showGroupDropdown, setShowGroupDropdown] = useState(false);
  const [showGroupEditModal, setShowGroupEditModal] = useState(false);
  const [showGroupDeleteModal, setShowGroupDeleteModal] = useState(false);
  const initialLoadComplete = useRef(false);
  const userMadeChange = useRef(false);
  const lastDataSnapshot = useRef({});
  const transcriptionContainerRef = useRef(null);
  const [greetChecked, setGreetChecked] = useState(false);
  const [askNameChecked, setAskNameChecked] = useState(false);
  const [testDriveChecked, setTestDriveChecked] = useState(false);

  const { state, manager } = useAppState();

  const handleRefresh = () => {
    window.location.reload(); // Refresh the page to fetch updates or re-fetch data as needed
    setShowNotification(false); // Optionally hide the notification after refreshing
  };

  // Effect for handling changes in state object
  useEffect(() => {
    setGroups(state.groups || []);
    setSelectGroup(state.selectGroup || {});
    setTeams(state.teams || []);
    setCards(state.cards || []);
    setTeamsAvailable(state.teamsAvailable || false);
    setGroupCards(groupCards || []);
  }, [state]);  // Reacts only to changes in the entire state object from AppState

  // Effect for updating group information when selectTeam changes
  useEffect(() => {
    if (manager && selectTeam?.id) {
      const updatedGroups = manager.listGroupsByTeam(selectTeam.id);
      setGroups(updatedGroups);
      fetchGroupCards();
    }
  }, [selectTeam, manager]);  // Reacts only to changes in selectTeam


  async function fetchGroupCards() {
    try {
      const groupCards = await manager.listCardsByGroup(selectGroup.id, selectTeam.id);

      if (selectTeam.groups.length === 0) {
        setGroupCards([]);
      } else {
        setGroupCards(groupCards);
      }
    } catch (error) {
      console.error("Error fetching group cards:", error);
    }
  }

  // Dedicated effect for selectTeam state changes
  useEffect(() => {
    setSelectTeam(state.selectTeam);
  }, [state.selectTeam]);

  // This effect should only run when 'state.groups' changes, not 'groups'
  useEffect(() => {
    setGroups(state.groups || []);
  }, [state.groups]);  // Depend only on 'state.groups'  

  // This is the use effect that sets real time listeners for DB changes
  // useEffect(() => {
  //   if (!selectTeam?.id) {
  //     return; // Early exit if selectTeam.id is not yet available
  //   }

  //   const db = getFirestore();
  //   const teamRef = doc(db, "teams", selectTeam.id);

  //   const unsubscribe = onSnapshot(teamRef, (docSnapshot) => {
  //     if (!initialLoadComplete.current) {
  //       // Initialize last known data and mark initial load complete
  //       if (docSnapshot.exists()) {
  //         lastDataSnapshot.current = docSnapshot.data();
  //       }
  //       initialLoadComplete.current = true;
  //       return; // Skip the first snapshot to adjust to initial data setup
  //     }

  //     if (docSnapshot.exists()) {
  //       const currentData = docSnapshot.data();
  //       let changesDetected = false;

  //       // Compare fields and ignore 'sessions'
  //       for (const key in currentData) {
  //         if (!['sessions', 'members'].includes(key) && JSON.stringify(currentData[key]) !== JSON.stringify(lastDataSnapshot.current[key])) {
  //           changesDetected = true;
  //           break;
  //         }
  //       }

  //       if (changesDetected) {
  //         if (!userMadeChange.current) {
  //           setShowNotification(true); // Trigger notification for changes outside 'sessions'
  //         } else {
  //           userMadeChange.current = false; // Reset the flag
  //         }
  //       }

  //       // Update the last known snapshot for the next comparison
  //       lastDataSnapshot.current = currentData;
  //     } else {
  //       console.log("Document does not exist");
  //     }
  //   }, (error) => {
  //     console.error("Error fetching team updates:", error);
  //   });

  //   return () => {
  //     unsubscribe();
  //     initialLoadComplete.current = false;
  //     lastDataSnapshot.current = {}; // Reset the last snapshot on cleanup
  //   };
  // }, [selectTeam?.id]);

  // const updateUserChange = () => {
  //   userMadeChange.current = true;
  // };

  useEffect(() => {
    const fetchGroupCards = async () => {
      if (manager && selectGroup?.id) {
        try {
          const groupCards = await manager.listCardsByGroup(selectGroup.id, selectTeam.id);
          if (selectTeam.groups.length === 0) {
            setGroupCards([]);
          } else {
            setGroupCards(groupCards);
          }
        } catch (error) {
          setGroupCards([]);
        }
      }
    };

    fetchGroupCards();
  }, [manager, selectGroup]);

  // const checkInterimTranscript = useCallback(() => {
  //   console.log(transcript);
  //   if (interimTranscript.includes('test drive')) {
  //     console.log("includes test drive");
  //     setTestDriveChecked(true);
  //   }
  //   if (interimTranscript.includes('who am I speaking to')) {
  //     setAskNameChecked(true);
  //   }
  //   if (interimTranscript.includes('good morning')) {
  //     setGreetChecked(true);
  //   }
  // }, [transcript]);

  const handleUpdateCardData = useCallback((data) => {
    // updateUserChange();
    if (!checkPermissions()) return;

    if (data.title.length > 0 && data.keywords.length > 0 && data.description.length > 0) {
      const updatedCard = {
        ...selectCard, // Assuming selectCard is part of the centralized state
        title: data.title,
        keywords: data.keywords,
        description: data.description,
      };

      if (manager) {
        manager.updateCardById(selectCard.id, updatedCard); // Use manager to update the card
        setGroupCards(currentCards => currentCards.map(card =>
          card.id === selectCard.id ? updatedCard : card
        )); // Update local state to reflect changes
      }
    }
  }, [selectCard, manager]);

  function checkPermissions() {
    const userPermissions = selectTeam.members.find(member => member.userId === user.uid);

    if (!userPermissions || (userPermissions.role !== 'admin' && userPermissions.role !== 'editor')) {
      alert("You do not have the permissions to perform this action.");
      return false;  // Indicates that the user does not have permission
    }

    return true;  // Indicates that the user has permission
  }

  const SpeechRecognition =
    window.SpeechRecognition || window.webkitSpeechRecognition;
  // const recognition = new SpeechRecognition();

  const recognition = useMemo(() => {
    return new SpeechRecognition();
  }, [SpeechRecognition]);

  function startSession() {
    if (!permissionGranted || !recognition) {
      console.error("Microphone permission not granted");
      return;
    }

    recognition.continuous = true;
    recognition.interimResults = true;
    recognition.deviceId = state.mic;
    recognition.start();
    setTranscript([]);
    setIsListening(true);
    setInterimTranscript('');
  }

  function stopSession() {
    createSession();
    setIsListening(false);
    setMatchingCards([]);
    setSessionCards([]);
    recognition.stop();
  }

  function handleSession() {
    if (!isListening) {
      if (selectGroup.title) {
        startSession();
      }
    } else {
      stopSession();
    }
  }

  const addCardToMatchingCards = useCallback(
    function (cardMatch) {
      if (!matchingCards.some((card) => card.id === cardMatch.id)) {
        setMatchingCards((prevMatchingCards) => [
          ...prevMatchingCards,
          cardMatch,
        ]);
        setSessionCards((prevSessionCards) => [
          ...prevSessionCards,
          cardMatch.id,
        ]);
      }
    },
    [matchingCards]
  );

  function removeFromMatchingCards(cardId) {
    setMatchingCards((prevMatchingCards) =>
      prevMatchingCards.filter((card) => card.id !== cardId)
    );
  }

  const onResult = useCallback(
    (e) => {
      const lastResultIndex = e.results.length - 1;
      const res = e.results[lastResultIndex];
  
      const transcriptText = res[0].transcript.trim().toLowerCase();
  
      if (res.isFinal) {
        setTranscript((prevTranscript) => [...prevTranscript, `${transcriptText}. `]);
        setInterimTranscript(''); // Clear the interim transcript
      } else {
        setInterimTranscript(transcriptText);
      }

      if (transcriptText.includes('test drive')) {
        console.log("includes test drive");
        setTestDriveChecked(true);
      }
      if (transcriptText.includes('your name')) {
        setAskNameChecked(true);
      }
      if (transcriptText.includes('good morning')) {
        setGreetChecked(true);
      }
  
      const addedCardIds = new Set();
  
      groupCards.forEach((groupCard) => {
        if (Array.isArray(groupCard.keywords)) {
          const keywordsArray = groupCard.keywords.map((keyword) => keyword.trim().toLowerCase());
  
          const keywordFound = keywordsArray.some((keyword) => {
            const regex = new RegExp(`\\b${escapeRegExp(keyword)}\\b`, 'i');
            return regex.test(transcriptText);
          });
  
          if (keywordFound && !addedCardIds.has(groupCard.id)) {
            const cardMatch = manager.getCardById(groupCard.id);
            addCardToMatchingCards(cardMatch);
            addedCardIds.add(groupCard.id);
          }
        } else {
          console.log(`Invalid or missing keywords for card ID ${groupCard.id}`);
        }
      });
    },
    [groupCards, addCardToMatchingCards, manager]
  );

  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  useEffect(() => {
    if (typeof SpeechRecognition === "undefined") {
      console.log("Can't access mic");
    } else {
      setPermissionGranted(true);
      if (recognition) {
        recognition.addEventListener("result", onResult);

        recognition.onend = () => {
          if (isListening) {
            recognition.continuous = true;
            recognition.interimResults = true;
            recognition.deviceId = state.mic;
            recognition.start();
          }
        };
      }
    }

    return () => {
      if (recognition) {
        recognition.removeEventListener("result", onResult);
      }
    };
  }, [onResult, SpeechRecognition, recognition, isListening]);

  const sendTranscriptToChatGPT = async (transcript) => {
    const apiKey = 'sk-proj-tGeYlTjktspuIcPK51WmT3BlbkFJYnVI7UX1LuuV1IofGL4q'; // Replace with your OpenAI API key

    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-3.5-turbo',
          messages: [
            {
              role: 'system',
              content: 'You are an assistant.',
            },
            {
              role: 'user',
              content: `Analyze this transcript and provide the following information, keep in mind it could be incomplete or multiple people talking. No more than one sentence of feedback for each item:
              - Conversion: true or false (based on whether a conversion likely happened based on an incomplete transcript)
              - Feedback: One positive comment and one area of improvement (considering the transcript is incomplete and there could be multiple people talking into one mic).
              
              Transcript: ${transcript}`,
            },
          ],
          max_tokens: 150, // Limit the response length
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );

      const feedback = response.data.choices[0].message.content;
      return feedback;
    } catch (error) {
      console.error('Error sending transcript to ChatGPT:', error);
      throw error;
    }
  };

  const createGroup = async () => {
    // updateUserChange();
    if (!checkPermissions()) return;
    if (groups.length >= 3) {
      alert("Max number of scenarios created!");
      return;
    }
    if (groupInputText.length > 0) {
      const groupData = {
        id: uuid(),
        title: groupInputText.trim(),
      };
      if (manager) {
        try {
          console.log(selectTeam.id);
          await manager.addNewGroup(selectTeam.id, groupData);

          // Fetch updated groups from the manager or assume the addition is synchronous
          const updatedGroups = [...groups, groupData];
          const teamId = selectTeam.id;


          const updatedTeams = state.teams.map(team => {
            if (team.id === teamId) {
              return { ...team, groups: updatedGroups };  // Update the specific team
            }
            return team; // Return the team unchanged if it does not match the condition
          });
          const team = updatedTeams.find(team => team.id === teamId);
          setSelectTeam(team);
          setTeams(updatedTeams);
          setGroups(updatedGroups);
          setSelectGroup(groups[groups.length - 1]);
          manager.setSelectTeam(team);
          manager.setGroups(updatedGroups);
          manager.setSelectGroup(updatedGroups[updatedGroups.length - 1]);

        } catch (error) {
          console.error("Error while adding new group:", error);
        }
      }
      setGroupInputText("");
      setShowCreateGroupModal(false);
    }
  };

  const updateGroup = async () => {
    // updateUserChange();
    if (!checkPermissions()) return;
    if (scenarioName.length > 0 && selectGroup.id) {
      const updatedGroupData = {
        ...selectGroup,
        title: scenarioName.trim()
      };

      if (manager) {
        try {
          await manager.updateGroupById(selectTeam.id, selectGroup.id, updatedGroupData);

          // Update groups in the local component state
          const updatedGroups = groups.map(group => {
            if (group.id === selectGroup.id) {
              return updatedGroupData;
            }
            return group;
          });

          const teamId = selectTeam.id;

          // Update the teams in the local component state
          const updatedTeams = state.teams.map(team => {
            if (team.id === teamId) {
              return { ...team, groups: updatedGroups };
            }
            return team;
          });

          const updatedSelectTeam = updatedTeams.find(team => team.id === teamId);
          if (updatedSelectTeam) {
            setSelectTeam(updatedSelectTeam);
            setTeams(updatedTeams);
            setGroups(updatedGroups);
            setSelectGroup(updatedGroupData); // Set the updated group as selected
            manager.setSelectTeam(updatedSelectTeam);
            manager.setGroups(updatedGroups);
            manager.setSelectGroup(updatedGroupData);
          }

        } catch (error) {
          console.error("Error while updating the group:", error);
        }
      }
    }
  };

  const handleSelectGroupChange = useCallback((e) => {
    const groupId = e.target.value;
    console.log(groupId);
    const group = groups.find(group => group.id === groupId);
    if (group) {
      manager.setSelectGroup(group);
      setSelectGroup(group);
      const groupCards = manager.listCardsByGroup(group.id, selectTeam.id);
      setGroupCards(groupCards);
    }
  }, [groups, manager, setSelectGroup, setGroupCards]); // Ensure all dependencies are listed

  const handleDeleteCard = (cardId) => {
    // updateUserChange();
    if (!checkPermissions()) return;
    if (manager) {
      manager.deleteCardById(cardId);
      const updatedCards = manager.listCardsByGroup(selectGroup.id, selectTeam.id); // Assuming listCardsByGroup is also synchronous
      setGroupCards(updatedCards); // Update UI after deleting the card
    }
  };

  const createCard = useCallback(() => {
    // updateUserChange();
    if (!checkPermissions()) return;
    if (groupCards.length >= 20) {
      alert("Max number of cards created!");
      return;
    }
    if (cardTitleText.length > 0 && cardTitleText.length < 50 && cardKeywords.length > 0 && cardKeywords.length < 100 && cardDesc.length > 0 && cardDesc.length < 10000) {
      const cardData = {
        id: uuid(),
        groupId: selectGroup.id, // Ensure selectGroup is valid and has an id
        title: cardTitleText.trim(),
        keywords: cardKeywords.split(",").map(keyword => keyword.trim()),
        description: cardDesc,
      };

      if (manager) {
        manager.addNewCard(selectGroup.id, cardData); // Use manager to add the new card
        const groupCards = manager.listCardsByGroup(selectGroup.id, selectTeam.id);
        setGroupCards(groupCards);
        // Do not directly update local state here; rely on manager to propagate changes
      }
    } else {
      alert("Title length, keyword length, or description length too large or empty!");
    }
    setCardTitleText("");
    setCardKeywords("");
    setCardDesc("");
    setShowCreateCardModal(false);
  }, [cardTitleText, cardKeywords, cardDesc, manager, selectGroup]);

  const createSession = useCallback(async () => {
    userMadeChange.current = true;
    const currentDate = new Date();
    let feedback;
    feedback = "Coming soon...";
    // CHATGPT API REQUEST 
    // try {
    //   feedback = await sendTranscriptToChatGPT(transcript.join(" "));
    // } catch (error) {
    //   alert(`Error getting feedback from AI: ${error.message}`);
    // }
    // const conversion = feedback.includes('Conversion: true') || feedback.includes('Conversion: True');
    const conversion = "Coming soon...";
    const sessionData = {
      id: uuid(),
      transcript: transcript.join(" "), // Ensure transcript is correctly managed as state or context
      dateTime: currentDate.toISOString(),
      owner: user.uid,
      cardsShown: sessionCards,
      conversion: conversion,
      feedback: feedback || "",
    };

    if (manager) {
      manager.addNewSession(selectTeam.id, sessionData); // Use the manager to add the new session
    }

    setTranscript([]); // Reset transcript after session is created
  }, [manager, transcript]);

  function closeCreateGroupModal() {
    setShowCreateGroupModal(false);
  }

  function closeCreateCardModal() {
    setShowCreateCardModal(false);
  }

  function closeCardModal() {
    setShowCardModal(false);
  }

  function closeMicSettingsModal() {
    setShowMicSettingsModal(false);
  }

  function handleGroupInputChange(e) {
    setGroupInputText(e.target.value);
  }

  function minimizeScripContainer() {
    document.getElementsByClassName('script-container')[0].style.height = "25px";
    document.getElementsByClassName('script-minimize')[0].style.display = "none";
    document.getElementsByClassName('script-maximize')[0].style.display = "block";
  }

  function maximizeScripContainer() {
    document.getElementsByClassName('script-container')[0].style.height = "500px";
    document.getElementsByClassName('script-maximize')[0].style.display = "none";
    document.getElementsByClassName('script-minimize')[0].style.display = "block";
  }

  const handleDeleteGroup = async () => {
    // updateUserChange();
    if (!checkPermissions()) return;
    if (manager && selectGroup.id) {
      try {
        await manager.deleteGroupById(selectGroup.id);

        // Filter out the deleted group
        const updatedGroups = groups.filter(group => group.id !== selectGroup.id);
        setGroups(updatedGroups);
        manager.setGroups(updatedGroups);

        // Find and update the current team's group list in the state
        const updatedTeams = state.teams.map(team => {
          if (team.id === selectTeam.id) {
            return { ...team, groups: updatedGroups }; // Update the specific team
          }
          return team;
        });

        // Set the teams with the updated data
        setTeams(updatedTeams);

        // Update the selected team if necessary
        const updatedSelectTeam = updatedTeams.find(team => team.id === selectTeam.id);
        if (updatedSelectTeam) {
          manager.setSelectTeam(updatedSelectTeam);
          setSelectTeam(updatedSelectTeam);
        }

        // Set the selected group to either the last in the list or reset if no groups remain
        if (updatedGroups.length > 0) {
          const newSelectGroup = updatedGroups[updatedGroups.length - 1];
          manager.setSelectGroup(newSelectGroup);
          setSelectGroup(newSelectGroup);
        } else {
          manager.setSelectGroup({});
          setSelectGroup({});
        }

      } catch (error) {
        console.error("Error while deleting the group:", error);
      }
      closeGroupDeleteModal();
    }
  };

  function tempCloseNotification() {
    document.getElementsByClassName("notification-bar")[0].style.display =
      "none";
  }

  const handleScenarioNameInputChange = useCallback((e) => {
    setScenarioName(e.target.value);
  }, [setScenarioName]);

  const GroupDeleteModal = () => {
    return (
      <div>
        <div className="scenario-modal-wrapper" onClick={closeGroupDeleteModal}></div>
        <div className="create-team-modal">
          <h3 className="modal-title">Are You Sure?</h3>
          <div className="close-button-holder" onClick={closeGroupDeleteModal}>
            <BsXLg />
          </div>
          <IoWarningOutline className="warning-icon" />
          <h3 className="delete-group-text">Deleting the scenario will delete all cards within it as well.</h3>
          <span className="delete-group-btn-modal" onClick={handleDeleteGroup}>
            Delete
          </span>
        </div>
      </div>
    )
  }

  const GroupDropdown = () => {
    return (
      <div className="group-dropdown-wrapper">
        <div className="group-options-dropdown">
          <div className="group-btn" onClick={handleGroupEditModal}><MdDeleteOutline className="group-btn-icon" />Edit name</div>
          <div className="group-btn" onClick={handleGroupDeleteModal}><MdDeleteOutline className="group-btn-icon" />Delete</div>
        </div>
      </div>
    )
  }

  const Notification = () => {
    return (
      <div className="notification-container">
        <p>New changes were made by a teammate, <button className="refresh-btn" onClick={handleRefresh}>refresh</button> to see them.</p>
      </div>
    )
  }

  const StartingScript = () => {
    return (
      <div className="script-container">
        <div className="group-text">Starter Script</div>
        <label className="container">
          <input type="checkbox" checked={greetChecked} readOnly />
          <span className="checkmark">Greet nicely</span>
        </label>
        <label className="container">
          <input type="checkbox" checked={askNameChecked} readOnly />
          <span className="checkmark">Ask for First Name</span>
        </label>
        <label className="container">
          <input type="checkbox" checked={testDriveChecked} readOnly />
          <span className="checkmark">Ask to come in for test drive</span>
        </label>
        <div className="script-minimize" onClick={minimizeScripContainer}><IoIosArrowUp /></div>
        <div className="script-maximize" onClick={maximizeScripContainer}><IoIosArrowDown /></div>
      </div>
    )
  }

  function handleGroupEditModal() {
    setShowGroupEditModal(true);
    closeGroupDropdown();
  }

  function handleGroupDeleteModal() {
    setShowGroupDeleteModal(true);
    closeGroupDropdown();
  }

  function handleGroupDropdown() {
    if (showGroupDropdown) {
      setShowGroupDropdown(false);
    } else {
      setShowGroupDropdown(true);
    }
  }

  function closeGroupDropdown() {
    setShowGroupDropdown(false);
  }

  function closeGroupEditModal() {
    setShowGroupEditModal(false);
  }

  function closeGroupDeleteModal() {
    setShowGroupDeleteModal(false);
    closeGroupDropdown();
  }

  function updateScenarioName() {
    updateGroup();
    setShowGroupEditModal(false);
  }

  function trialUpgrade() {
    alert("Thanks for your feedback!");
    manager.upgradeRequest();
  }

  useEffect(() => {
    if (transcriptionContainerRef.current) {
      transcriptionContainerRef.current.scrollTop = transcriptionContainerRef.current.scrollHeight;
    }
  }, [transcript, interimTranscript]);

  return (
    <section className="group-page-section">
      {showNotification && (
        <Notification />
      )}
      {showGroupEditModal && (
        <GroupEditModal
          closeModal={closeGroupEditModal}
          updateScenarioName={updateScenarioName}
          scenarioName={scenarioName}
          handleScenarioNameInputChange={handleScenarioNameInputChange} />
      )}
      {showGroupDeleteModal && (
        <GroupDeleteModal />
      )}
      <div className="right-holder">
        <div className="top-bar">
          <div className="search">
            {/* <div className="search-bar">
              <div className="search-icon">
                <CiSearch />
              </div>
            </div> */}
            <div className="free-trial-text">Free Trial (3 scenarios, 20 cards)</div>
            {state.upgradeRequested = false && (
              <div className="free-trial-upgrade" onClick={trialUpgrade}>Would pay for this</div>
            )}
          </div>
          {/* <div className="user-holder">
            <div className="user-img">
              <img src={user?.photoURL} className="google-profile-img" />
            </div>
            <div className="user-name">{user?.displayName}</div>
          </div> */}
        </div>
        <div className="content-holder bg-dots">
          <div className="modal-container">
            {/* <StartingScript /> */}
            {showMicSettingsModal && (
              <MicSettingsModal closeModal={closeMicSettingsModal} />
            )}
            {showCreateGroupModal && (
              <CreateGroupModal
                groupInputText={groupInputText}
                handleGroupInputChange={handleGroupInputChange}
                createGroup={createGroup}
                closeModal={closeCreateGroupModal}
              />
            )}
            {showCreateCardModal && (
              <CreateCardModal
                cardTitleText={cardTitleText}
                cardKeywords={cardKeywords}
                cardDesc={cardDesc}
                setCardTitleText={setCardTitleText}
                setCardKeywords={setCardKeywords}
                setCardDesc={setCardDesc}
                createCard={createCard}
                closeModal={closeCreateCardModal}
              />
            )}
            {showCardModal && (
              <CardModal
                cardData={selectCard}
                closeModal={closeCardModal}
                deleteCard={handleDeleteCard}
                onSave={handleUpdateCardData}
              />
            )}
            {matchingCards.map((card) => (
              <OpenCardModal
                key={card.id}
                cardData={card}
                removeCard={removeFromMatchingCards}
              />
            ))}
          </div>
          <div className="create-group-container">
            {isListening ? (
              <div className="listening-container">
                <div className="live-transcription-container" id="transcription-container" ref={transcriptionContainerRef}>
                  {transcript.map((text, index) => (
                    <p key={index}>{text}</p>
                  ))}
                  {interimTranscript && <p className="interim-results">{interimTranscript}</p>}
                </div>
                <div className="listening">Listening...</div>
              </div>
            ) : (
              ""
            )}
            <div className="group-horizontal-holder">
              <div className="group-text">Scenario</div>
              <span
                className="create-group-btn"
                onClick={() => {
                  if (showCreateGroupModal) {
                    setShowCreateGroupModal(false);
                  } else {
                    setShowCreateGroupModal(true);
                  }
                }}
              >
                <FaRegSquarePlus />
              </span>
            </div>
            <div className="group-container">
              <select
                className="groups-dropdown"
                onChange={handleSelectGroupChange}
                value={selectGroup?.id || ''} // Use the group's id as the value
              >
                {(!groups || groups.length === 0) && <option>No available scenarios</option>}
                {groups && groups.map((group) => (
                  <option key={group.id} value={group.id}>
                    {group.title}
                  </option>
                ))}
              </select>
            </div>
            <div className="group-horizontal-holder">
              <div className="group-text card-text">Cards</div>
              {selectGroup?.id && (
                <span
                  className="create-group-btn"
                  onClick={() => {
                    if (showCreateCardModal) {
                      setShowCreateCardModal(false);
                    } else {
                      setShowCreateCardModal(true);
                    }
                  }}
                >
                  <FaRegSquarePlus />
                </span>
              )}
            </div>
            <div className="card-holder">
              {Array.isArray(groupCards) ? groupCards.map((card) => (
                <div
                  className="card"
                  key={card.id}  // Assuming 'card' will always be an object with an 'id' if it exists
                  onClick={() => {
                    setShowCardModal(!showCardModal);
                    if (!showCardModal) {
                      setSelectCard(card);
                    }
                  }}
                >
                  {card.title}
                </div>
              )) : null}
            </div>
            {selectGroup?.id && (
              <div className="selected-group-text">
                Selected Scenario: {selectGroup.title}
                <BiDotsHorizontalRounded
                  className="dropdown-btn"
                  // onClick={handleDeleteGroup}
                  onClick={handleGroupDropdown}
                />
                {showGroupDropdown && (
                  <GroupDropdown />
                )}
              </div>
            )}
            {selectGroup?.id && (
              <div className="session-btn-holder">
                {isListening ? (
                  <span
                    className="start-session-btn stop-session"
                    onClick={handleSession}
                  >
                    <div className="btn-container">
                      <BsFileFill />{" "}
                      <div className="session-text">Stop Session</div>
                    </div>
                  </span>
                ) : (
                  <span className="start-session-btn" onClick={handleSession}>
                    <div className="btn-container">
                      <BsFillCaretRightFill />{" "}
                      <div className="session-text">Start Session</div>
                    </div>
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default CreateGroupPage;
