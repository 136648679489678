import React, { useCallback, useEffect, useState } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer, Tooltip } from 'recharts';
import { BsXLg } from "react-icons/bs";
import { CiSearch } from "react-icons/ci";
import CreateTeamModal from "../../components/create-team-modal/create-team-modal";
import MembersPage from "./members";
import TeamSettingsPage from "./team-settings";
import { IoIosArrowDown } from "react-icons/io";
import { useAppState } from '../../context/appstate';
import { useAuth } from "../../context/auth";
import uuid from "uuid-random";
import { getFirestore, doc, getDoc } from "firebase/firestore";

function TeamsPage() {
    const { user, logOut } = useAuth();
    const [sessions, setSessions] = useState([]);
    const [categorizedCards, setCategorizedCards] = useState([]);
    const [recentSessions, setRecentSessions] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedSession, setSelectedSession] = useState(null);
    const [chartData, setChartData] = useState([]);
    const [appStateManager, setAppStateManager] = useState(null);
    const [teams, setTeams] = useState([]);
    const [selectTeam, setSelectTeam] = useState({});
    const [teamsAvailable, setTeamsAvailable] = useState(false);
    const [showCreateTeamModal, setShowCreateTeamModal] = useState(false);
    const [teamInputText, setTeamInputText] = useState("");
    const [showMembersPage, setShowMembersPage] = useState(false);
    const [showTeamSettingsPage, setShowTeamSettingsPage] = useState(false);
    const [processedUsers, setProcessedUsers] = useState([]);
    const [ownerName, setOwnerName] = useState('');
    const [highlightedKeyword, setHighlightedKeyword] = useState("");
    const [selectedSessionCards, setSelectedSessionCards] = useState([]);
    const [conversionCount, setConversionCount] = useState(0);
    const { state, manager } = useAppState();

    const calculateCardConversionRates = useCallback(() => {
      if (sessions.length === 0) return;
    
      const totalSessions = sessions.length;
    
      const cardConversionCounts = {};
    
      sessions.forEach(session => {
        if (session.conversion) {
          session.cardsShown.forEach(cardId => {
            cardConversionCounts[cardId] = (cardConversionCounts[cardId] || 0) + 1;
          });
        }
      });
    
      const categorizedCardsArray = Object.keys(cardConversionCounts).map(cardId => {
        const conversionCount = cardConversionCounts[cardId];
        const conversionRate = (conversionCount / totalSessions) * 100;
    
        // Find the card details in selectTeam
        let cardDetails = null;
        selectTeam.groups.forEach(group => {
          const card = group.cards.find(card => card.id === cardId);
          if (card) {
            cardDetails = card;
          }
        });
    
        return { 
          cardId, 
          conversionRate: conversionRate.toFixed(2),
          cardDetails
        };
      });
    
      setCategorizedCards(categorizedCardsArray);
    }, [sessions, selectTeam]);
    
    useEffect(() => {
      if (sessions.length > 0) {
        calculateCardConversionRates();
      }
    }, [sessions, calculateCardConversionRates]);

    const getCardDetails = useCallback(() => {
      if (!selectedSession || !state.selectTeam.groups) return [];

      const shownCardDetails = [];
      state.selectTeam.groups.forEach(group => {
          group.cards.forEach(card => {
              if (selectedSession.cardsShown?.includes(card.id)) {
                  shownCardDetails.push({
                      title: card.title,
                      keywords: card.keywords.join(', '),
                      description: card.description
                  });
              }
          });
      });
      return shownCardDetails;
    }, [selectedSession, state.selectTeam]);

    const parseTranscript = useCallback((transcript) => {
      if (!transcript || !selectedSessionCards.length) return [{ text: transcript, isKeyword: false }];
  
      let parsedTranscript = [];
      let lastIndex = 0;
  
      // Flatten and sort keywords by length descending to prioritize longer matches
      const keywords = selectedSessionCards.flatMap(card =>
          card.keywords.split(', ').map(keyword => keyword.trim().toLowerCase())
      ).sort((a, b) => b.length - a.length);
  
      // Add "test drive" to the keywords list if not already present
      if (!keywords.includes("test drive")) {
          keywords.push("test drive");
      }
  
      // Create a regex from the keywords, escaping special characters
      const keywordsRegex = new RegExp(keywords.map(kw => kw.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join('|'), 'gi');
  
      // Use the regex to find and mark keywords in the transcript
      transcript.replace(keywordsRegex, (match, offset) => {
          // Push text before the keyword (if any)
          if (offset > lastIndex) {
              parsedTranscript.push({ text: transcript.substring(lastIndex, offset), isKeyword: false });
          }
          // Check if the match is "test drive"
          if (match.toLowerCase() === "test drive") {
              parsedTranscript.push({ text: match, isKeyword: true, highlight: 'lightgreen' });
          } else {
              // Push the keyword
              parsedTranscript.push({ text: match, isKeyword: true });
          }
          lastIndex = offset + match.length;
      });
  
      // Add remaining text if any
      if (lastIndex < transcript.length) {
          parsedTranscript.push({ text: transcript.substring(lastIndex), isKeyword: false });
      }
  
      return parsedTranscript;
    }, [selectedSessionCards]);

    useEffect(() => {
      
    }, [highlightedKeyword]);

    useEffect(() => {
      if (selectedSession) {
        const cardDetails = getCardDetails();
        setSelectedSessionCards(cardDetails); // Assume this is another state hook to hold card details
      }
    }, [selectedSession, getCardDetails]);

    useEffect(() => {
      if (state && manager && selectTeam && state.teams && selectTeam.id) {
          // Find the team with the selected team ID
          const team = state.teams.find(team => team.id === state.selectTeam.id);
  
          if (team && team.sessions) {
              // Set sessions from the selected team and sort them by dateTime from most recent to oldest
              const sortedSessions = [...team.sessions].sort((a, b) => {
                  // Assuming dateTime is stored in an ISO format or comparable string that sorts correctly
                  return new Date(a.dateTime) - new Date(b.dateTime);
              });
              setSessions(sortedSessions);
          } else {
              // If no sessions or team is found, set an empty array
              setSessions([]);
          }
  
          setTeams(state.teams || []);
          setSelectTeam(state.selectTeam || {});
          setTeamsAvailable(state.teamsAvailable || false);
      }
    }, [state, manager, selectTeam, user.uid]);  // Make sure dependencies are correctly listed  

    useEffect(() => {
      // Ensure selectTeam is set to an object or defaults to an empty object if state.selectTeam is undefined
      setSelectTeam(state.selectTeam || {});
    }, [state.selectTeam]); // Dependency on state.selectTeam to trigger useEffect when it changes

    useEffect(() => {
      setChartData(processDataForChart(sessions));
      setRecentSessions(processRecentSessions(sessions));

      let conversionCount = 0;
      sessions?.forEach(session => {
          if (session.conversion) {
              conversionCount++;
          }
      });
      setConversionCount(conversionCount);
    }, [sessions]);  // This effect runs whenever `sessions` changes

    useEffect(() => {
      const loadOwnerName = async () => {
        if (selectedSession && selectedSession.owner) {
          const userDetails = await fetchUserDetails(selectedSession.owner);
          setOwnerName(userDetails.name); // Store the fetched name in state
        }
      };
  
      loadOwnerName();
    }, [selectedSession]);

    const processRecentSessions = (sessions) => {
      const sortedSessions = sessions.sort((a, b) => {
        // Assuming dateTime is stored in an ISO format or comparable string that sorts correctly
        return new Date(b.dateTime) - new Date(a.dateTime);
      });
      setRecentSessions(sortedSessions);
    }
    
    const processDataForChart = (sessions) => {
      const countsByDate = {};
  
      sessions?.forEach(session => {
          const date = new Date(session.dateTime).toLocaleDateString();
          if (!countsByDate[date]) {
              countsByDate[date] = { date, conversations: 0, conversions: 0 };
          }
          countsByDate[date].conversations += 1; // Increment total sessions
          if (session.conversion) {
              countsByDate[date].conversions += 1; // Increment conversions if true
          }
      });
  
      return Object.values(countsByDate);
    };
  

    // useEffect(() => {
    //   const updateSessions = () => {
    //     if (manager) {
    //       const loadedSessions = manager.state.sessions;
    //       setSessions(loadedSessions);
    //       setChartData(processDataForChart(loadedSessions));
    //     }
    //   };
    
    //   updateSessions();
    // }, [manager]);      

    const viewSessionDetails = (sessionId) => {
        const session = sessions.find(session => session.id === sessionId);
        setSelectedSession(session);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedSession(null);
    };

    const closeCreateTeamModal = () => {
      setShowCreateTeamModal(false);
    };

    const handleCreateTeam = () => {
      if (teamInputText.length > 0) {
        const teamId = uuid();  // Generate ID to be used as the Firestore document ID
        const teamData = {
          name: teamInputText.trim(),
          members: [{
              userId: user.uid,
              role: 'admin'
          }],
          owner: user.uid,
          groups: [],
          id: teamId,
        };
        if (manager) {
          setTeams([...teams, teamData]); 
          setSessions([]);
          manager.createTeam(teamId, teamData);
          manager.setSelectTeam(teamData);
          manager.setSelectGroup({});
        }
      }
      setTeamInputText("");
      setShowCreateTeamModal(false);
    };    

    function handleTeamInputChange(e) {
      setTeamInputText(e.target.value);
    }

    function handleShowMembersPage() {
      setShowMembersPage(true);
      setShowTeamSettingsPage(false);
    }

    function handleShowTeamSettingsPage() {
      setShowTeamSettingsPage(true);
      setShowMembersPage(false);
    }

    function showOverviewPage() {
      setShowMembersPage(false);
      setShowTeamSettingsPage(false);
    }

    const getUserSessionsCount = (sessions) => {
      const userStats = {};
    
      sessions.forEach(session => {
        // Initialize user stats if not already present
        if (!userStats[session.owner]) {
          userStats[session.owner] = {
            uid: session.owner,
            count: 0,        // Initialize session count
            conversions: 0,  // Initialize conversions count
            name: null,      // Placeholder for name
          };
        }
    
        // Increment session count
        userStats[session.owner].count += 1;
    
        // Increment conversion count if conversion is true
        if (session.conversion) {
          userStats[session.owner].conversions += 1;
        }
      });
    
      return Object.values(userStats);
    };    


    const fetchUserDetails = async (userId) => {
      const db = getFirestore();
      const userRef = doc(db, "users", userId);
      const userSnap = await getDoc(userRef);
      return userSnap.exists() ? userSnap.data() : { name: 'Unknown User', email: 'No email provided' };
    };

    const enrichUserSessionsWithNamesAndEmails = async (userSessions) => {
      const promises = userSessions.map(async (userSession) => {
        const userDetails = await fetchUserDetails(userSession.uid);  // Assume this fetches all relevant details
        return { 
          ...userSession,
          name: userDetails.name || 'Unknown User',
          email: userDetails.email || 'No email provided'
        };
      });
      return Promise.all(promises);
    };
    
    useEffect(() => {
      const processAndSetUsers = async () => {
        const userSessionsCount = getUserSessionsCount(sessions);
        const userSessionsWithNames = await enrichUserSessionsWithNamesAndEmails(userSessionsCount);
        const sortedUsers = userSessionsWithNames.sort((a, b) => b.count - a.count);
        setProcessedUsers(sortedUsers);
      };
    
      if (sessions.length > 0) {
        processAndSetUsers();
      }
    }, [sessions]);    

    const handleKeywordHover = useCallback((text) => {
      const keyword = text.toLowerCase().replace(/[.,!?;]+$/, ''); // Strip punctuation for matching
      setHighlightedKeyword(keyword);
    }, []);  
  
    const handleKeywordLeave = useCallback(() => {
        setHighlightedKeyword(null);
    }, []);  

    const isCardHighlighted = useCallback((card) => {
      if (!highlightedKeyword) return false;
      return card.keywords.toLowerCase().split(', ').some(kw => {
          // Adding word boundaries to ensure complete keyword matching
          const regex = new RegExp(`\\b${kw}\\b`, 'i'); // Ensures complete word match
          const matchResult = regex.test(highlightedKeyword);
          return matchResult;
      });
    }, [highlightedKeyword]);

    if (!state || !state.teams || !selectTeam) {
      return <div>Loading...</div>;  // Or any other loading indicator
    }

    if (selectTeam.name === 'Private Workspace' && teams.length > 1) {
        return (
            <div className="private-workspace-message">
                Private workspaces don't have team data.
            </div>
        );
    }

    return (
        <section className="teams-page-section">
            <div className="top-bar">
                <div className="search">
                    <div className="search-bar">
                        <div className="search-icon"><CiSearch /></div>
                    </div>
                </div>
            </div>
            <div className="teams-content-holder">
              {showCreateTeamModal && (
                  <CreateTeamModal
                      teamInputText={teamInputText}
                      handleTeamInputChange={(e) => setTeamInputText(e.target.value)}
                      closeModal={() => setShowCreateTeamModal(false)}
                      createTeam={handleCreateTeam}
                  />
              )}
              {teams.length > 1 ? (
              <div className="teams-modal-container">
                <div className="slider-container">
                  <div className={`slider-item ${!showMembersPage && !showTeamSettingsPage ? 'active-slider-item' : ''}`} onClick={showOverviewPage}>
                    Overview
                  </div>
                  <div className={`slider-item ${showMembersPage && !showTeamSettingsPage ? 'active-slider-item' : ''}`} onClick={handleShowMembersPage}>
                    Members
                  </div>
                  <div className={`slider-item ${showTeamSettingsPage ? 'active-slider-item' : ''}`} onClick={handleShowTeamSettingsPage}>
                    Team Settings
                  </div>
                </div>
                {showMembersPage ? (
                  <MembersPage />
                ) : showTeamSettingsPage ? (
                  <TeamSettingsPage />
                ) :
                <div className="activity-holder">
                  <div className="date-picker-container">
                    <div className="date-picker">Last 30 days<IoIosArrowDown /></div>
                  </div>
                    <div className="metrics-component-container">
                      <div className="main-metrics-container">
                        <div className="main-metric">
                          <div className="main-metric-title">Total Sessions</div>
                          <div className="main-metric-value">{sessions.length}</div>
                        </div>
                        <div className="main-metric">
                          <div className="main-metric-title">Total Conversions</div>
                          <div className="main-metric-value">{conversionCount}</div>
                        </div>
                        <div className="main-metric">
                          <div className="main-metric-title">Top Mention</div>
                          <div className="main-metric-value">2020 Jeep Grand Cherokee</div>
                        </div>
                        <div className="main-metric no-margin">
                          <div className="main-metric-title">Top Card Shown</div>
                          <div className="main-metric-value metric-card">Sales and Offers</div>
                        </div>
                      </div>
                    </div>

                    <div className="component-container">
                      <div className="small-title">Card Review</div>
                      <div className="title-description">Conversion rate of your top cards</div>
                      <div className="card-review-container">
                        <div className="card-holder">
                          <div className="card-container">
                            {categorizedCards.map(({ cardId, conversionRate, cardDetails }, index) => (
                              <div className="card-shown" key={index}>
                                <div>{cardDetails?.title || "removed"}</div>
                                <div>{conversionRate}%</div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="component-container">
                      <div className="small-title">Sales Performance</div>
                      <div className="title-description">List of conversations over the last week</div>
                      <ResponsiveContainer width="100%" height={300}>
                        <LineChart
                          data={chartData}
                          margin={{
                              top: 25,
                              right: 30,
                              bottom: 5,
                              left: 0,
                          }}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="date" />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          <Line type="monotone" dataKey="conversations" stroke="#8884d8" activeDot={{ r: 8 }} />
                          <Line type="monotone" dataKey="conversions" stroke="#82ca9d" activeDot={{ r: 8 }} />
                        </LineChart>
                      </ResponsiveContainer>
                    </div>

                    <div className="component-container">
                      <div className="small-title">Top Performers</div>
                      <div className="title-description">List of best performing team members based on session count</div>
                      <div className="table-holder">
                        <table>
                            <thead>
                                <tr>
                                    <th>NAME</th>
                                    <th>EMAIL</th>
                                    <th># OF SESSIONS</th>
                                    <th>CONVERSIONS</th>
                                    <th>ACTIONS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {processedUsers.map((user, index) => (
                                    <tr key={index}>
                                        <td>{user.name || "not found"}</td>
                                        <td>{user.email}</td>
                                        <td>{user.count}</td>
                                        <td>{user.conversions}</td>
                                        <td>
                                            {/* Actions like View Details, Edit, Delete, etc. */}
                                            View Details
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {showModal && (
                            <div className="modal">
                                <h3 className="session-modal-title">User Details</h3>
                                {/* Additional details modal content */}
                                <button className="close-session-modal-button" onClick={closeModal}><BsXLg /></button>
                            </div>
                        )}
                        {showModal && (
                            <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 999 }} onClick={closeModal}></div>
                        )}
                      </div>
                    </div>
                    <div className="component-container">
                      <div className="small-title">Recent Sessions</div>
                      <div className="title-description">List of recent sessions</div>
                      <div className="table-holder">
                        <table>
                            <thead>
                                <tr>
                                    <th>NAME</th>
                                    <th>DATE/TIME</th>
                                    <th># OF CARDS USED</th>
                                    <th>ACTIONS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sessions?.map((session, index) => (
                                    <tr key={index} onClick={() => viewSessionDetails(session.id)}>
                                        <td>{`Session ${index + 1}`}</td>
                                        <td>{new Date(session.dateTime).toLocaleString()}</td>
                                        {/* Assuming you store card count in the session or calculate it */}
                                        <td>{session.cardsShown.length || '0'}</td>
                                        <td>
                                            {/* Actions like View Details, Edit, Delete, etc. */}
                                            View
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {showModal && (
                            <div className="modal">
                                <h3 className="session-modal-title">Session Details</h3>
                                <p className="small-title">Date/Time</p>
                                <div className="title-description">{selectedSession && new Date(selectedSession.dateTime).toLocaleString()}</div>
                                <p className="small-title">Conversion</p>
                                <div className="title-description">{selectedSession.conversion ? "true" : "false"}</div>
                                <p className="small-title">Cards Shown</p>
                                <div className="title-description cards-shown-container">
                                    {selectedSessionCards.map((card, index) => (
                                        <div key={card.id || index} className={`shown-card ${isCardHighlighted(card) ? 'highlight' : ''}`}>
                                            <p>{card.title}</p>
                                        </div>
                                    ))}
                                </div>
                                <p className="small-title">AI Feedback</p>
                                <div className="title-description">{selectedSession.feedback || "none"}</div>
                                <p className="small-title">Transcript</p>
                                <div className="title-description overflow-auto">
                                {selectedSession && parseTranscript(selectedSession.transcript).map((segment, index) => (
                                  <span 
                                      key={`${segment.text}-${index}`} 
                                      className={segment.isKeyword ? "keyword" : ""} 
                                      onMouseEnter={() => segment.isKeyword && handleKeywordHover(segment.text)} 
                                      onMouseLeave={handleKeywordLeave}
                                      style={{ backgroundColor: segment.highlight || (segment.isKeyword ? 'yellow' : 'transparent') }} // Set lightgreen for "test drive", yellow for other keywords
                                  >
                                      {segment.text}
                                  </span>
                                ))}
                                </div>
                                <button className="close-session-modal-button" onClick={closeModal}><BsXLg /></button>
                            </div>
                        )}
                        {/* Overlay to cover the rest of the screen when modal is open */}
                        {showModal && (
                            <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 999 }} onClick={closeModal}></div>
                        )}
                      </div>
                    </div>
                </div>
                }
              </div>
                ) : (
                    <div className="create-team-btn" onClick={() => setShowCreateTeamModal(!showCreateTeamModal)}>
                        Create Team
                    </div>
                )}
            </div>
        </section>
    );
}

export default TeamsPage;
