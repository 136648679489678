import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import AppStateManager from '../state-manager';

const AppStateContext = createContext();

export const AppStateProvider = ({ children, userId }) => {
    const [appState, setAppState] = useState({});
    const managerRef = useRef(null);

    useEffect(() => {
        if (userId && !managerRef.current) {  // Initialize manager only if not already initialized
            managerRef.current = new AppStateManager(userId, (newState) => {
                setAppState({ ...newState }); // Update state whenever AppStateManager changes
            });
            managerRef.current.initState().then(() => {
                setAppState(managerRef.current.state);  // Set initial state from manager
            });
        }
    }, [userId]);

    // Provide both manager and its state in the context value
    const contextValue = {
        state: appState,
        manager: managerRef.current
    };

    return (
        <AppStateContext.Provider value={contextValue}>
            {children}
        </AppStateContext.Provider>
    );
};

export const useAppState = () => useContext(AppStateContext);
