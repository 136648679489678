import React, { useState, useEffect } from 'react';
import hero from "../../assets/hero.png";
import logo from "../../assets/logo.svg";
import logoWhite from "../../assets/logo-white.svg";
import analytics from "../../assets/analytics.png";
import implementation from "../../assets/implementation.png";
import badexample from "../../assets/badexample.png";
import { RiChatVoiceLine } from "react-icons/ri";
import { GrInfo } from "react-icons/gr";
import { SiTheconversation } from "react-icons/si";
import { useAuth } from "../../context/auth";
import { useNavigate } from "react-router-dom";

import "./styles.css";

function HomePage() {

  const { googleSignin, user } = useAuth();
  const navigate = useNavigate();
  const [justLoggedIn, setJustLoggedIn] = useState(false);
  const [inputText, setInputText] = useState("");

  function handleInputChange(e) {
    setInputText(e.target.value);
    if (inputText === 'googl') {
      handleLoginWithGoogle();
    }
  }

  async function handleLoginWithGoogle() {
    try {
      await googleSignin();
      setJustLoggedIn(true); // Set justLoggedIn to true after a successful login
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (user && justLoggedIn) {
      navigate('/create');
      setJustLoggedIn(false); // Reset the justLoggedIn flag after navigating
    }
  }, [user, justLoggedIn, navigate]);

  return (
    <div className="homepage">
      {/* <div className="bg-effect"></div> */}
      {/* top navigation */}
      <div className="homepage-nav">
        <div className="homepage-logo">
          <img src={logo} alt="" className="logo-img" />
        </div>
        <div className="homepage-nav-holder">
          <div
            className="homepage-nav-btns"
            onClick={() => {
              document.getElementById("features").scrollIntoView({
                block: "start",
                behavior: "smooth",
                inline: "start",
              });
            }}
          >
            Features
          </div>
          <div className="greeting-buttons-container">
            <div onClick={handleLoginWithGoogle} className="homepage-nav-btns-main">Try Free</div>
          </div>
        </div>
      </div>
      {/* hero section */}
      <div className="hero-section">
        <div className="circle"></div>
        <div className="circle2"></div>
        <div className="hero-heading fade">
          Your Meeting Wingman: Unlocking the Right Info at the Right Time.
        </div>
        <div className="hero-helper-text fade">
          Empower your conversations with instant access to the right information exactly when you need it. Our tool listens, matches, and displays your pre-loaded cards seamlessly, so you can focus on making meaningful connections, not remembering details.
        </div>
        <div
          className="greeting-buttons-container fade"
        >
          <button onClick={handleLoginWithGoogle} className="modal-board-button free-trial">Try Free</button>
        </div>
        <div className="hero-holder">
          {/* <img
            src={hero}
            className="hero-image"
            alt="Hero section"
          ></img> */}
          <iframe width="1100" height="620" src="https://www.youtube.com/embed/AVLBJsLW6Wc?si=ONaYDWE0dpZe-NLl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
      </div>
      {/* three helper section */}
      <div className="helper-section" id="features">
        <div className="helper-section-group">
          <div className="helper-icon">
            <RiChatVoiceLine />
          </div>
          <div className="helper-title">Real Time Transcription</div>
          <div className="helper-description">
            VoiceCue transcribes your speech into text in real time
          </div>
        </div>
        <div className="helper-section-group">
          <div className="helper-icon">
            <GrInfo />
          </div>
          <div className="helper-title">Surface Key Information</div>
          <div className="helper-description">
            It then surfaces relevant cards to you at blazing speed
          </div>
        </div>
        <div className="helper-section-group">
          <div className="helper-icon">
            <SiTheconversation />
          </div>
          <div className="helper-title">Natural Conversations</div>
          <div className="helper-description">
            It allows you to keep the natural moment going, without looking for notes
          </div>
        </div>
      </div>
      <div className="showcase-section">
        <div className="showcase-group">
          <div className="showcase-text-holder">
            <div className="overline">Autonomous Efficiency</div>
            <div className="showcase-title">Instantly Become a World-Class Sales Rep</div>
            <div className="showcase-content">
              Never fumble through long lists of notes again. VoiceCue allows you to naturally speak and pay attention to your customer, automatically surfacing cards to you when you need them the most.
            </div>
            <div className="cta-holder">
              <div
                onClick={handleLoginWithGoogle}
              >
                <div className="showcase-link">Try Free</div>
              </div>
              <div
                className="homepage-nav-btns"
                onClick={() => {
                  document.getElementById("access").scrollIntoView({
                    block: "start",
                    behavior: "smooth",
                    inline: "start",
                  });
                }}
              >
                I'm Not Convinced
              </div>
            </div>
          </div>
          <img
            src={badexample}
            className="showcase-img-holder"
            alt="Dislays apps features"
          />
        </div>
      </div>

      <div className="showcase-section" id="access">
        <div className="showcase-group reverse">
          <div className="showcase-text-holder">
            <div className="overline">One Source of Truth</div>
            <div className="showcase-title">
              All Your Information In One Product.
            </div>
            <div className="showcase-content">
              Ever catch yourself having to flip between your notes, your CRM customer information, and wherever else? Use VoiceCue to keep all your info in cards to get surfaced automatically in real time during conversations.
            </div>
            <div className="cta-holder">
              <div
                onClick={handleLoginWithGoogle}
              >
                <div className="showcase-link">Try Free</div>
              </div>
              <div
                className="homepage-nav-btns"
                onClick={() => {
                  document.getElementById("juggle").scrollIntoView({
                    block: "start",
                    behavior: "smooth",
                    inline: "start",
                  });
                }}
              >
                Hm Not Quite.
              </div>
            </div>
          </div>
          <img
            src={implementation}
            className="showcase-img-holder"
            alt="shows app feature"
          />
        </div>
      </div>

      <div className="showcase-section" id="juggle">
        <div className="showcase-group">
          <div className="showcase-text-holder">
            <div className="overline">Learn From Your Meetings</div>
            <div className="showcase-title">Look at Historic Data to Maximize Efficiency</div>
            <div className="showcase-content">
              Our advanced analytics tools allow you to look at historic conversations and make it clear which cards are working and which need updating.
            </div>
            <div className="cta-holder">
              <div
                onClick={handleLoginWithGoogle}
              >
                <div className="showcase-link">Try Free</div>
              </div>
              <div
                className="homepage-nav-btns"
                onClick={handleLoginWithGoogle}
              >
                Ok, I'll Try It!
              </div>
            </div>
          </div>
          <img
            src={analytics}
            className="showcase-img-holder"
            alt="shows app feature"
          />
        </div>
      </div>

      {/* <div className="showcase-section" id="figma">
      <div className="showcase-group reverse">
        <div className="showcase-text-holder">
          <div className="overline">Integrations</div>
          <div className="showcase-title">Figma Integration</div>
          <div className="showcase-content">
            You can paste a Figma embed code straight into a card to show your
            prototypes to your team, with live updates.
          </div>
          <div className="cta-holder">
            <Link
              to={{
                pathname: "/create",
                state: { entry: "figma-cta" },
              }}
            >
              <div className="showcase-link">Try Free</div>
            </Link>
            <Link
              to={{
                pathname: "/create",
                state: { entry: "figma-helper-cta" },
              }}
            >
              <div className="homepage-nav-btns">Alright, I'll Try It!</div>
            </Link>
          </div>
        </div>
        <img
          // src={figma}
          className="showcase-img-holder"
          alt="shows app feature"
        />
      </div>
    </div> */}
      {/* cta section */}
      <div className="cta-section">
        <div className="cta-text">Ready to Maximize Your Sales Potential?</div>
        <div>
          <div onClick={handleLoginWithGoogle} className="cta-btn">Try it out!</div>
        </div>
        {/* <input className='hidden-input' type="text" onChange={handleInputChange} value={inputText} /> */}
      </div>
      {/* cta section */}
      <div className="footer-section">
        <div className="footer-nav">
          <div className="homepage-logo">
            <img src={logoWhite} alt="" className="logo-white" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
