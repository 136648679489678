import React from "react";
import { BsXLg } from "react-icons/bs";
import { useEffect, useState } from "react";
import AppStateManager from "../../state-manager";
import { useAuth } from "../../context/auth";

import "./styles.css";

function MicSettingsModal({ closeModal }) {
  const { user, logOut } = useAuth();
  const [microphones, setMicrophones] = useState([]);
  const [selectedMic, setSelectedMic] = useState("");
  const [appStateManager, setAppStateManager] = useState(null);

  useEffect(() => {
    if (user) {
      const manager = new AppStateManager(user.uid);
      manager.initState().then(() => {
        setAppStateManager(manager);
      });
    }
  }, [user]);

  useEffect(() => {
    if (!appStateManager) return;
  
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const mics = devices.filter((device) => device.kind === "audioinput");
      setMicrophones(mics);
      if (mics.length > 0) {
        setSelectedMic(mics[0].deviceId); // Automatically select the first mic
        appStateManager.addMic(mics[0].deviceId);
      }
    });
  }, [appStateManager]);
  
  const handleMicChange = (event) => {
    const selectedDeviceId = event.target.value;
    setSelectedMic(selectedDeviceId);
    appStateManager.addMic(selectedDeviceId);
  };  

  return (
    <div>
      <h3 className="small-title">Mic Settings</h3>
      <div className="close-button-holder" onClick={closeModal}>
        
      </div>
      <div id="settingsPanel">
        <div className="title-description">Select your microphone</div>
        <select
          style={{ marginBottom: "35px", border: "1px solid #e9e9e9", height: "40px", borderRadius: "3px", padding: "0px 25px" }}
          value={selectedMic}
          onChange={handleMicChange}
        >
          {microphones.map((mic) => (
            <option key={mic.deviceId} value={mic.deviceId}>
              {mic.label || "Microphone " + (microphones.indexOf(mic) + 1)}
            </option>
          ))}
        </select>
        {/* Add more settings options here as needed */}
      </div>
    </div>
  );
}

export default MicSettingsModal;
