import React from "react";
import { BsXLg } from "react-icons/bs";

import "./styles.css";

function CreateModalButton({
  groupInputText,
  handleGroupInputChange,
  createGroup,
  closeModal,
}) {
  return (
    <div className="create-group-modal">
      <h3 className="modal-title">Create Scenario</h3>
      <div className="close-button-holder" onClick={closeModal}>
        <BsXLg />
      </div>
      <input
        type="text"
        placeholder="Scenario Name"
        className="group-name-input"
        value={groupInputText}
        onChange={handleGroupInputChange}
      />
      <span className="create-group-btn-modal" onClick={createGroup}>
        Save
      </span>
    </div>
  );
}

export default CreateModalButton;
