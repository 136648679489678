import React from "react";
import { BsXLg } from "react-icons/bs";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import "./styles.css";

const modules = {
  toolbar: false,
};

function OpenCardModal({ cardData, removeCard }) {
  return (
    <div className="open-card-modal">
      <h3 className="open-modal-title">{cardData.title || "Card Title"}</h3>
      <div
        className="close-button-holder"
        onClick={() => {
          removeCard(cardData.id);
        }}
      >
        <BsXLg />
      </div>
      <ReactQuill
        className="open-card-description"
        theme="snow"
        modules={modules}
        value={cardData.description}
        readOnly={true}
      />
    </div>
  );
}

export default OpenCardModal;
