import React, { useCallback, useEffect, useState } from "react";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
import { useAuth } from "../../context/auth";
import { useAppState } from "../../context/appstate"; 
import TeamName from "./team-name";
import "./styles.css";

function TeamSettingsPage() {
    const { user, logOut } = useAuth();
    const [selectTeam, setSelectTeam] = useState(null); 
    const [showTeamNameModal, setShowTeamNameModal] = useState(false);
    const [teamName, setTeamName] = useState("");
    const [teams, setTeams] = useState([]);

    const { state, manager } = useAppState();

    useEffect(() => {
        if (state && manager) {
            setSelectTeam(state.selectTeam || {});
            setTeams(state.teams || []);
        }
    }, [state, manager]);

    useEffect(() => {
        // Ensure selectTeam is set to an object or defaults to an empty object if state.selectTeam is undefined
        setSelectTeam(state.selectTeam || {});
    }, [state.selectTeam]); // Dependency on state.selectTeam to trigger useEffect when it changes
  
    function openUpdateTeamNameModal() {
        setShowTeamNameModal(true);
    };

    function closeUpdateTeamNameModal() {
        setShowTeamNameModal(false);
    }

    function handleTeamNameInputChange(e) {
        setTeamName(e.target.value);
    }

    const saveTeamName = async () => {
        if (teamName.trim().length > 0 && selectTeam) {
            const db = getFirestore();
            const teamRef = doc(db, "teams", selectTeam.id);  // Reference to the team document
    
            try {
                // Update the team's name in Firestore
                await updateDoc(teamRef, {
                    name: teamName.trim()
                });
                console.log("Team name updated successfully.");
    
                // Update the local state via state manager
                if (manager && manager.setSelectTeam && teams) {
                    const updatedTeam = {...selectTeam, name: teamName.trim()};
                    console.log(updatedTeam);
                    manager.setSelectTeam(updatedTeam);
    
                    // Also update the teams array
                    const updatedTeams = teams.map(team => 
                        team.id === selectTeam.id ? updatedTeam : team
                    );
                    manager.setTeams(updatedTeams);  
                    setTeams(updatedTeams); 
                    setSelectTeam(updatedTeam);  
                }
    
            } catch (error) {
                console.error("Error updating team name:", error);
            }
            setTeamName("");
            setShowTeamNameModal(false);
        } else {
            console.log("Invalid team name or no team selected.");
        }
    };    

    if (!state || !state.teams || !selectTeam) {
        return <div>Loading...</div>;  
    };
    
    return (
    <div className="activity-holder">
        {showTeamNameModal &&
            <TeamName 
            closeModal={closeUpdateTeamNameModal}
            teamName={teamName}
            handleTeamNameInputChange={handleTeamNameInputChange}
            saveTeamName={saveTeamName}
            />
        }
        <div className="members-container">
            <div className="component-container">
                {/* <div className="member-title-btn-container">
                    <div className="small-title">Team Settings</div>
                </div>
                <div className="title-description">List of team settings</div> */}
                <div className="member-title-btn-container">
                    <div className="small-title">Team Name</div>
                    <div className="secondary-btn" onClick={openUpdateTeamNameModal}>Edit Team Name</div>
                </div>
                <div className="title-description">{selectTeam.name}</div>
            </div>
        </div>
    </div>
    );
}

export default TeamSettingsPage;
