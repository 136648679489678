import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"; // Import Firestore
import { getAuth } from 'firebase/auth'; // Import Authentication

const firebaseConfig = {
  apiKey: "AIzaSyBa4HU5t-gJpF7sq1Zy7V_LcRjPaL6LxPI",
  authDomain: "voicecue-76f16.firebaseapp.com",
  projectId: "voicecue-76f16",
  storageBucket: "voicecue-76f16.appspot.com",
  messagingSenderId: "198067408339",
  appId: "1:198067408339:web:c4727c8022350ae625b326",
  measurementId: "G-KBZ9HWEQS2"
};

// Initialize Firebase App
const app = initializeApp(firebaseConfig);

// Initialize Firestore
export const db = getFirestore(app);

// Initialize Firebase Auth
export const auth = getAuth(app);
